import Api from '../../api/Api'
const kategorisikap = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        datall: [],
        details: {},
        bykelas: [],
        bykelasmapel: [],
        bykelasmapelwalas: []
    },

    //mutations
    mutations: {

        SET_DATA(state, datas) {
            state.datas = datas
        },

        SET_ALL(state, dataall) {
            state.dataall = dataall
        },

        GET_DETAIL(state, details) {
            state.details = details
        },

        GET_BYKELAS(state, bykelas) {
            state.bykelas = bykelas
        },

        GET_BYKELASMAPEL(state, bykelasmapel) {
            state.bykelasmapel = bykelasmapel
        },

        GET_BYKELASMAPEL_WALAS(state, bykelasmapelwalas) {
            state.bykelasmapelwalas = bykelasmapelwalas
        },
    },

    _actions: {

        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kategorisikap/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        getKategori({ commit }, payload) {


            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kategorisikap/getbybebanmengajar?id=${payload}`)
                .then(response => {
                    // console.log('ini kategori', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_BYKELAS', response.data.data)

                })
        },

        getKategoriMapel({ commit }, payload) {


            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kategorisikap/getbybebanmengajarmapel?id=${payload}`)
                .then(response => {

                    commit('GET_BYKELASMAPEL', response.data.data)

                })
        },

        getKategoriMapelWalas({ commit }, payload) {

            console.log(payload)
            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kategorisikap/getbybebanmengajarmapelwalas?id=${payload}`)
                .then(response => {
                    console.log('walas kategori', response.data.data);
                    commit('GET_BYKELASMAPEL_WALAS', response.data.data)

                })
        },


        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let tahunajaran = payload ? payload.tasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kategorisikap?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${tahunajaran}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getAll({ commit }, payload) {
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kategorisikap/all?group=${payload}`)
                .then(response => {

                    commit('SET_ALL', response.data.data)
                })
        },


        store({ commit }, formData) {
            //console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/kategorisikap', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        generateSemester2({ commit }, formData) {
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/kategorisikap/generatesemester2', formData)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { kategorisikapId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`


                Api.post(`/kategorisikap/update/${kategorisikapId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/kategorisikap/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {

        getKategoriMapel(state) {
            return state.bykelasmapel
        },

        getData(state) {
            return state.datas
        },

        getDetail(state) {
            return state.details
        },

        getAll(state) {
            return state.dataall
        },

        getKategori(state) {
            return state.bykelas
        },

        getKategoriMapelWalas(state) {
            return state.bykelasmapelwalas
        }
    }

}

export default kategorisikap