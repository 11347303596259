<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Master Mapel Ceklis {{ mapel.nama_mapel }} {{level.name}}</h5>
              <!-- {{ $route.params }} -->
              <router-link
                :to="{
                  name: 'admin-mastermapelceklis-listjadwal',
                  params: {
                    id: $route.params.idunit,
                    idta: $route.params.idta,
                  },
                }"
                class="btn btn-danger btn-sm"
                >kembali</router-link
              >
            </div>
          </div>

          <div class="card-body" v-if="isVisible">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-12">
                      <!-- <h5>Indikator</h5> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="row">
                      <div class="form-group">
                        <!-- <label for="">Element</label> -->
                        <select
                          class="form-control select2"
                          @change="changeElement"
                          v-model="cp.masterelement_id"
                        >
                          <option value="">-- select Elemen--</option>
                          <option
                            v-for="u in elements"
                            :key="u.id"
                            :value="u.id"
                          >
                            {{ u.nama_element }}
                          </option>
                        </select>
                        <small
                          >Jika tidak ada elemen yang sesuai maka klik
                          <span
                            @click="openModal"
                            class="text-primary cursor-pointer"
                            style="text-decoration: none; cursor: pointer"
                            ><b>tambahkan</b></span
                          ></small
                        >
                        <small
                          class="text-danger"
                          v-if="validation.masterelement_id"
                          >{{ validation.masterelement_id[0] }}</small
                        >
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <!-- <label for="">Cp</label> -->
                          <select
                            class="form-control select2"
                            v-model="indikator.mastercp_id"
                          >
                            <option value="">-- select Cp --</option>
                            <option v-for="u in cps" :key="u.id" :value="u.id">
                              {{ u.nama_cp }}
                            </option>
                          </select>
                          <small
                            >Jika tidak ada cp yang sesuai maka klik
                            <span
                              @click="openModalCp"
                              class="text-primary cursor-pointer"
                              style="text-decoration: none; cursor: pointer"
                              ><b>tambahkan</b></span
                            ></small
                          >
                          <small
                            class="text-danger"
                            v-if="validation.mastercp_id"
                            >{{ validation.mastercp_id[0] }}</small
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <!-- <label for="">Nama Indikator</label> -->
                        <textarea
                          class="form-control"
                          placeholder="Nama Indikator"
                          v-model="indikator.nama_indikator"
                        />
                        <small
                          class="text-danger"
                          v-if="validation.nama_indikator"
                          >{{ validation.nama_indikator[0] }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <!-- <button type="submit" class="btn btn-success btn-sm">
                        Tambah Indikator
                      </button> -->

                      <button
                        type="submit"
                        class="btn btn-success btn-sm"
                        :disabled="isLoading"
                      >
                        <span v-if="isLoading">Loading...</span>
                        <span v-else>Tambah Indikator</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="card-body">
            <button
              class="btn btn-success"
              @click="toggleVisibility"
              v-if="!isVisible"
            >
              Tambahkan data
            </button>
            <button
              class="btn btn-danger"
              @click="toggleDisable"
              v-if="isVisible"
            >
              Tutup
            </button>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <table>
                  <template v-for="(e, index) in elements" :key="e.id">
                    <thead>
                      <tr v-if="index == 0">
                        <th>Capaian Pembelajaran</th>
                        <th>Indikator Capaian Pembelajaran</th>
                      </tr>
                      <tr v-if="e.nama_element">
                        <th colspan="2">
                          <i
                            class="fa fa-pen text-primary cursor-pointer"
                            style="text-decoration: none; cursor: pointer"
                            @click="editElement(e)"
                          ></i>
                          <i
                            class="fa fa-trash text-danger"
                            @click="confirmDeleteElement(e.id)"
                            style="text-decoration: none; cursor: pointer"
                          ></i
                          >&nbsp; &nbsp;{{ e.nama_element }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-if="e.nama_element">
                        <tr v-for="c in e.cp" :key="c.id">
                          <td :style="{ width: '50%' }">
                            <i
                              @click="editCp(c)"
                              class="fa fa-pen text-primary"
                              style="text-decoration: none; cursor: pointer"
                            ></i>
                            <i
                              class="fa fa-trash text-danger"
                              @click="confirmDeleteCp(c.id)"
                              style="text-decoration: none; cursor: pointer"
                            ></i>
                            &nbsp;
                            {{ c.nama_cp }}  
                          </td>
                          <td style="vertical-align: middle">
                            <ul class="m0">
                              <li
                                v-for="i in c.indikator"
                                :key="i.id"
                                class="my-4"
                                style="list-style: none"
                              >
                                <i
                                  class="fa fa-pen text-primary"
                                  @click="editIndikator(i, e)"
                                  style="text-decoration: none; cursor: pointer"
                                ></i>
                                <i
                                  @click="confirmDeleteIndikator(i.id)"
                                  class="fa fa-trash text-danger"
                                  style="text-decoration: none; cursor: pointer"
                                ></i>
                                {{ i.nama_indikator }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td :style="{ width: '50%' }">
                            <i
                              @click="editCp(e)"
                              class="fa fa-pen text-primary"
                              style="text-decoration: none; cursor: pointer"
                            ></i>
                            <i
                              class="fa fa-trash text-danger"
                              @click="confirmDeleteCp(e.id)"
                              style="text-decoration: none; cursor: pointer"
                            ></i>
                            &nbsp;
                            {{ e.nama_cp }}
                          </td>
                          <td style="vertical-align: middle">
                            <ul class="m0">
                              <li
                                v-for="i in e.indikator"
                                :key="i.id"
                                class="my-4"
                                style="list-style: none"
                              >
                                <i
                                  class="fa fa-pen text-primary"
                                  @click="editIndikator(i, e)"
                                  style="text-decoration: none; cursor: pointer"
                                ></i>
                                <i
                                  @click="confirmDeleteIndikator(i.id)"
                                  class="fa fa-trash text-danger"
                                  style="text-decoration: none; cursor: pointer"
                                ></i>
                                &nbsp;
                                {{ i.nama_indikator }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" v-if="showModalElementEdit">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Element</h5>
              <button
                @click="closeModalElementEdit"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="updateElement">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Nama Element</label>
                      <input
                        class="form-control"
                        placeholder="Nama Element"
                        v-model="element.nama_element"
                      />

                      <small
                        class="text-danger"
                        v-if="validation.nama_element"
                        >{{ validation.nama_element[0] }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="closeModalElementEdit"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModalCpEdit">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Capaian Pembelajaran</h5>
              <button
                @click="closeModalCpEdit"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="updateCp">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Element</label>
                      <select
                        class="form-control select2"
                        v-model="cp.masterelement_id"
                      >
                        <option value="">-- select Elament--</option>
                        <option v-for="u in elements" :key="u.id" :value="u.id">
                          {{ u.nama_element }}
                        </option>
                      </select>
                      <small
                        class="text-danger"
                        v-if="validation.masterelement_id"
                        >{{ validation.masterelement_id[0] }}</small
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Nama Cp</label>
                    <textarea
                      class="form-control"
                      placeholder="Nama Element"
                      v-model="cp.nama_cp"
                    />
                    <small class="text-danger" v-if="validation.nama_cp">{{
                      validation.nama_cp[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="closeModalCpEdit"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModalIndikatorEdit">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Indikator</h5>
              <button
                @click="closeModalIndikatorEdit"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="updateIndikator">
              <div class="modal-body">
                <div class="row">
                  <div class="row">
                    <div class="form-group">
                      <label for="">Elemen</label>
                      <select
                        class="form-control select2"
                        @change="changeElement"
                        v-model="cp.masterelement_id"
                      >
                        <option value="">-- select Elemen--</option>
                        <option v-for="u in elements" :key="u.id" :value="u.id">
                          {{ u.nama_element }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Cp</label>
                        <select
                          class="form-control select2"
                          v-model="indikator.mastercp_id"
                        >
                          <option value="">-- select Cp --</option>
                          <option v-for="u in cps" :key="u.id" :value="u.id">
                            {{ u.nama_cp }}
                          </option>
                        </select>

                        <small
                          class="text-danger"
                          v-if="validation.mastercp_id"
                          >{{ validation.mastercp_id[0] }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Nama Indikator</label>
                      <textarea
                        class="form-control"
                        placeholder="Nama Indikator"
                        v-model="indikator.nama_indikator"
                      />
                      <small
                        class="text-danger"
                        v-if="validation.nama_indikator"
                        >{{ validation.nama_indikator[0] }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="closeModalIndikatorEdit"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModal">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Tambah Elemen</h5>
              <button
                @click="closeModal"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="saveelement">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Nama Elemen</label>
                      <input
                        class="form-control"
                        placeholder="Nama Elemen"
                        v-model="element.nama_element"
                      />
                      <small
                        class="text-danger"
                        v-if="validation.nama_element"
                        >{{ validation.nama_element[0] }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="closeModal"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModalCp">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Tambah Capaian Pembelajaran</h5>
              <button
                @click="closeModalCp"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="savecp">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Elemen</label>
                      <select
                        class="form-control select2"
                        v-model="cp.masterelement_id"
                      >
                        <option value="">-- select Elamen--</option>
                        <option v-for="u in elements" :key="u.id" :value="u.id">
                          {{ u.nama_element }}
                        </option>
                      </select>
                      <small
                        class="text-danger"
                        v-if="validation.masterelement_id"
                        >{{ validation.masterelement_id[0] }}</small
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">Nama Cp</label>
                    <textarea
                      class="form-control"
                      placeholder="Nama Element"
                      v-model="cp.nama_cp"
                    />
                    <small class="text-danger" v-if="validation.nama_cp">{{
                      validation.nama_cp[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  @click="closeModalCp"
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "kelasIndex",
  components: {},
  data() {
    return {
      showkelas: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state

    const isLoading = ref(false);

    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: "",
    });

    const isVisible = ref(false);

    // Mendefinisikan metode untuk mengubah nilai isVisible
    const toggleVisibility = () => {
      isVisible.value = true;
    };

    const toggleDisable = () => {
      isVisible.value = false;
    };

    const route = useRoute();

    const showModal = ref(false);
    const showModalCp = ref(false);

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const openModalCp = () => {
      showModalCp.value = true;
    };

    const closeModalCp = () => {
      showModalCp.value = false;
    };

    const selectedElement = ref(null);

    // Show/hide modal
    const showModalElementEdit = ref(false);

    const showModalCpEdit = ref(false);

    const showModalIndikatorEdit = ref(false);

    // Method to handle edit icon click
    const editElement = (e) => {
      // console.log(e.nama_element);
      element.nama_element = e.nama_element;
      element.id = e.id;
      showModalElementEdit.value = true;
    };

    const editCp = (c) => {
      cp.nama_cp = c.nama_cp;
      cp.masterelement_id = c.masterelement_id;
      cp.id = c.id;
      showModalCpEdit.value = true;
    };

    const editIndikator = (i, e) => {
      // console.log(e.nama_element);
      cp.masterelement_id = e.id;

      indikator.nama_indikator = i.nama_indikator;
      indikator.mastercp_id = i.mastercp_id;
      indikator.id = i.id;
      showModalIndikatorEdit.value = true;
    };

    // Method to close the modal
    const closeModalElementEdit = () => {
      showModalElementEdit.value = false;
      selectedElement.value = null;
    };

    const closeModalCpEdit = () => {
      showModalCpEdit.value = false;
      selectedElement.value = null;
    };

    const closeModalIndikatorEdit = () => {
      showModalIndikatorEdit.value = false;
      selectedElement.value = null;
    };

    const element = reactive({
      id: "",
      nama_element: "",
      id_ta: route.params.idta,
      id_unit: route.params.idunit,
      id_mapel: route.params.id,
      id_unitlevel: route.params.idunitlevel,
    });

    const cp = reactive({
      masterelement_id: "",
      id_ta: route.params.idta,
      id_unit: route.params.idunit,
      id_mapel: route.params.id,
      id_unitlevel: route.params.idunitlevel,
      nama_cp: "",
    });

    const indikator = reactive({
      mastercp_id: "",
      id: "",
      id_ta: route.params.idta,
      id_unit: route.params.idunit,
      id_mapel: route.params.id,
      id_unitlevel: route.params.idunitlevel,
      nama_indikator: "",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const changeElement = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("cp/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        id_mapel: element.id_mapel,
        id_unit: element.id_unit,
        id_ta: element.id_ta,
        id_unitlevel: element.id_unitlevel,
        elementid: cp.masterelement_id,
      });
    };

    //function register, fungsi ini di jalankan ketika form di submit
    function saveelement() {
      //define variable

      let formData = new FormData();

      formData.append("id_ta", element.id_ta);
      formData.append("id_unit", element.id_unit);
      formData.append("id_mapel", element.id_mapel);
      formData.append("id_unitlevel", element.id_unitlevel);
      formData.append("nama_element", element.nama_element);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("element/store", formData)
        .then(() => {
          showModal.value = false;
          //redirect ke dashboard
          store.dispatch("element/getDataOld", {
            id_mapel: element.id_mapel,
            id_unit: element.id_unit,
            id_ta: element.id_ta,
            id_unitlevel: element.id_unitlevel,
          });

          router.push({
            name: "admin-mastermapelceklis-form",
            params: {
              id: element.id_mapel,
              idunit: element.id_unit,
              idta: element.id_ta,
              id_unitlevel: element.id_unitlevel,
            },
          });

          element.nama_element = "";
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    function savecp() {
      //define variable

      let formData = new FormData();

      formData.append("id_ta", cp.id_ta);
      formData.append("id_unit", cp.id_unit);
      formData.append("id_mapel", cp.id_mapel);
      formData.append("id_unitlevel", cp.id_unitlevel);
      formData.append("masterelement_id", cp.masterelement_id);
      formData.append("nama_cp", cp.nama_cp);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("cp/store", formData)
        .then(() => {
          showModalCp.value = false;
          //redirect ke dashboard
          store.dispatch("element/getDataOld", {
            id_mapel: element.id_mapel,
            id_unit: element.id_unit,
            id_ta: element.id_ta,
            id_unitlevel: element.id_unitlevel,
          });

          store.dispatch("cp/getData", {
            id_mapel: cp.id_mapel,
            id_unit: cp.id_unit,
            id_ta: cp.id_ta,
            id_unitlevel: cp.id_unitlevel,
          });

          router.push({
            name: "admin-mastermapelceklis-form",
            params: {
              id: element.id_mapel,
              idunit: element.id_unit,
              idta: element.id_ta,
              id_unitlevel: element.id_unitlevel,
            },
          });

          cp.masterelement_id = "";
          cp.nama_cp = "";

          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    const handleSubmit = async () => {
      // Cek jika sudah dalam keadaan loading
      if (isLoading.value) return;

      isLoading.value = true; // Set loading menjadi true

      try {
        // Simulasi pengiriman data (ganti dengan logika pengiriman Anda)
        await saveindikator(); // Misalnya, panggil API di sini
      } catch (error) {
        console.error("Error submitting data:", error);
        // Tangani error jika perlu
      } finally {
        isLoading.value = false; // Set loading kembali ke false setelah selesai
      }
    };

    function saveindikator() {
      //define variable

      let formData = new FormData();

      formData.append("id_ta", indikator.id_ta);
      formData.append("id_unit", indikator.id_unit);
      formData.append("id_mapel", indikator.id_mapel);
      formData.append("mastercp_id", indikator.mastercp_id);
      formData.append("id_unitlevel", indikator.id_unitlevel);
      formData.append("nama_indikator", indikator.nama_indikator);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("indikator/store", formData)
        .then(() => {
          indikator.mastercp_id = "";
          indikator.nama_indikator = "";
          //redirect ke dashboard
          store.dispatch("element/getDataOld", {
            id_mapel: element.id_mapel,
            id_unit: element.id_unit,
            id_ta: element.id_ta,
            id_unitlevel: element.id_unitlevel,
          });

          store.dispatch("cp/getData", {
            id_mapel: cp.id_mapel,
            id_unit: cp.id_unit,
            id_ta: cp.id_ta,
            id_unitlevel: cp.id_unitlevel,
          });

          router.push({
            name: "admin-mastermapelceklis-form",
            params: {
              id: element.id_mapel,
              idunit: element.id_unit,
              idta: element.id_ta,
              idunitlevel: element.id_unitlevel,
            },
          });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    function updateElement() {
      let formData = new FormData();

      formData.append("id_ta", element.id_ta);
      formData.append("id_unit", element.id_unit);
      formData.append("id_mapel", element.id_mapel);
      formData.append("id_unitlevel", element.id_unitlevel);
      formData.append("nama_element", element.nama_element);
      // console.log(formData);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("element/update", {
          elementId: element.id,
          payload: formData,
        })
        .then(() => {
          showModalElementEdit.value = false;
          //redirect ke dashboard
          store.dispatch("element/getDataOld", {
            id_mapel: element.id_mapel,
            id_unit: element.id_unit,
            id_ta: element.id_ta,
            id_unitlevel: element.id_unitlevel,
          });

          router.push({
            name: "admin-mastermapelceklis-form",
            params: {
              id: element.id_mapel,
              idunit: element.id_unit,
              idta: element.id_ta,
              id_unitlevel: element.id_unitlevel,
            },
          });

          element.nama_element = "";
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    function confirmDeleteElement(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch("element/destroy", id)
            .then((response) => {
              console.log(response);
              // Handle response from the server
              if (response.success) {
                Swal.fire({
                  title: "Berhasil",
                  text: response.data.message,
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });

                showModalElementEdit.value = false;
                //redirect ke dashboard
                store.dispatch("element/getDataOld", {
                  id_mapel: element.id_mapel,
                  id_unit: element.id_unit,
                  id_ta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                });

                router.push({
                  name: "admin-mastermapelceklis-form",
                  params: {
                    id: element.id_mapel,
                    idunit: element.id_unit,
                    idta: element.id_ta,
                    id_unitlevel: element.id_unitlevel,
                  },
                });

                element.nama_element = "";
                // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
              } else {
                Swal.fire({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  confirmButtonColor: "#d33",
                  confirmButtonText: "OK",
                });

                showModalElementEdit.value = false;
                //redirect ke dashboard
                store.dispatch("element/getDataOld", {
                  id_mapel: element.id_mapel,
                  id_unit: element.id_unit,
                  id_ta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                });

                router.push({
                  name: "admin-mastermapelceklis-form",
                  params: {
                    id: element.id_mapel,
                    idunit: element.id_unit,
                    idta: element.id_ta,
                    id_unitlevel: element.id_unitlevel,
                  },
                });

                element.nama_element = "";
                // Lakukan hal lain jika diperlukan setelah penghapusan gagal
              }
            })
            .catch((error) => {
              console.error(error);
              // Handle error jika terjadi
              Swal.fire({
                title: "Error",
                text: "Terjadi kesalahan saat menghapus data",
                icon: "error",
                confirmButtonColor: "#d33",
                confirmButtonText: "OK",
              });

              showModalElementEdit.value = false;
              //redirect ke dashboard
              store.dispatch("element/getDataOld", {
                id_mapel: element.id_mapel,
                id_unit: element.id_unit,
                id_ta: element.id_ta,
                id_unitlevel: element.id_unitlevel,
              });

              router.push({
                name: "admin-mastermapelceklis-form",
                params: {
                  id: element.id_mapel,
                  idunit: element.id_unit,
                  idta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                },
              });

              element.nama_element = "";
            });
        }
      });
    }

    function updateCp() {
      let formData = new FormData();

      formData.append("id_ta", cp.id_ta);
      formData.append("id_unit", cp.id_unit);
      formData.append("id_mapel", cp.id_mapel);
      formData.append("id_unitlevel", cp.id_unitlevel);
      formData.append("nama_cp", cp.nama_cp);
      formData.append("masterelement_id", cp.masterelement_id);
      // console.log(formData);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("cp/update", {
          cpId: cp.id,
          payload: formData,
        })
        .then(() => {
          showModalCpEdit.value = false;
          //redirect ke dashboard
          store.dispatch("element/getDataOld", {
            id_mapel: element.id_mapel,
            id_unit: element.id_unit,
            id_ta: element.id_ta,
            id_unitlevel: element.id_unitlevel,
          });

          router.push({
            name: "admin-mastermapelceklis-form",
            params: {
              id: element.id_mapel,
              idunit: element.id_unit,
              idta: element.id_ta,
              id_unitlevel: element.id_unitlevel,
            },
          });

          // cp.nama_cp = '';
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    function confirmDeleteCp(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch("cp/destroy", id)
            .then((response) => {
              console.log(response.data);
              // Handle response from the server
              if (response.data.success) {
                Swal.fire({
                  title: "Berhasil",
                  text: response.data.message,
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });

                showModalCpEdit.value = false;
                //redirect ke dashboard
                store.dispatch("element/getDataOld", {
                  id_mapel: element.id_mapel,
                  id_unit: element.id_unit,
                  id_ta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                });

                router.push({
                  name: "admin-mastermapelceklis-form",
                  params: {
                    id: element.id_mapel,
                    idunit: element.id_unit,
                    idta: element.id_ta,
                    id_unitlevel: element.id_unitlevel,
                  },
                });

                element.nama_element = "";
                // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
              } else {
                Swal.fire({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  confirmButtonColor: "#d33",
                  confirmButtonText: "OK",
                });

                showModalCpEdit.value = false;
                //redirect ke dashboard
                store.dispatch("element/getDataOld", {
                  id_mapel: element.id_mapel,
                  id_unit: element.id_unit,
                  id_ta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                });

                router.push({
                  name: "admin-mastermapelceklis-form",
                  params: {
                    id: element.id_mapel,
                    idunit: element.id_unit,
                    idta: element.id_ta,
                    id_unitlevel: element.id_unitlevel,
                  },
                });

                // element.nama_element = '';
                // Lakukan hal lain jika diperlukan setelah penghapusan gagal
              }
            })
            .catch((error) => {
              console.error(error);
              // Handle error jika terjadi
              Swal.fire({
                title: "Error",
                text: "Terjadi kesalahan saat menghapus data",
                icon: "error",
                confirmButtonColor: "#d33",
                confirmButtonText: "OK",
              });

              showModalCpEdit.value = false;
              //redirect ke dashboard
              store.dispatch("element/getDataOld", {
                id_mapel: element.id_mapel,
                id_unit: element.id_unit,
                id_ta: element.id_ta,
                id_unitlevel: element.id_unitlevel,
              });

              router.push({
                name: "admin-mastermapelceklis-form",
                params: {
                  id: element.id_mapel,
                  idunit: element.id_unit,
                  idta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                },
              });

              // element.nama_element = '';
            });
        }
      });
    }

    function updateIndikator() {
      let formData = new FormData();

      formData.append("id_ta", indikator.id_ta);
      formData.append("id_unit", indikator.id_unit);
      formData.append("id_mapel", indikator.id_mapel);
      formData.append("id_unitlevel", indikator.id_unitlevel);
      formData.append("nama_indikator", indikator.nama_indikator);
      formData.append("mastercp_id", indikator.mastercp_id);
      // console.log(formData);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("indikator/update", {
          indikatorId: indikator.id,
          payload: formData,
        })
        .then(() => {
          showModalIndikatorEdit.value = false;
          //redirect ke dashboard
          store.dispatch("element/getDataOld", {
            id_mapel: element.id_mapel,
            id_unit: element.id_unit,
            id_ta: element.id_ta,
            id_unitlevel: element.id_unitlevel,
          });

          router.push({
            name: "admin-mastermapelceklis-form",
            params: {
              id: element.id_mapel,
              idunit: element.id_unit,
              idta: element.id_ta,
              id_unitlevel: element.id_unitlevel,
            },
          });

          // cp.nama_cp = '';
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    function confirmDeleteIndikator(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: `Apakah Anda yakin ingin menghapus data ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch("indikator/destroy", id)
            .then((response) => {
              console.log(response.data);
              // Handle response from the server
              if (response.data.success) {
                Swal.fire({
                  title: "Berhasil",
                  text: response.data.message,
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "OK",
                });

                showModalCpEdit.value = false;
                //redirect ke dashboard
                store.dispatch("element/getDataOld", {
                  id_mapel: element.id_mapel,
                  id_unit: element.id_unit,
                  id_ta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                });

                router.push({
                  name: "admin-mastermapelceklis-form",
                  params: {
                    id: element.id_mapel,
                    idunit: element.id_unit,
                    idta: element.id_ta,
                    id_unitlevel: element.id_unitlevel,
                  },
                });

                element.nama_element = "";
                // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
              } else {
                Swal.fire({
                  title: "Gagal",
                  text: response.data.message,
                  icon: "error",
                  confirmButtonColor: "#d33",
                  confirmButtonText: "OK",
                });

                showModalCpEdit.value = false;
                //redirect ke dashboard
                store.dispatch("element/getDataOld", {
                  id_mapel: element.id_mapel,
                  id_unit: element.id_unit,
                  id_ta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                });

                router.push({
                  name: "admin-mastermapelceklis-form",
                  params: {
                    id: element.id_mapel,
                    idunit: element.id_unit,
                    idta: element.id_ta,
                    id_unitlevel: element.id_unitlevel,
                  },
                });

                // element.nama_element = '';
                // Lakukan hal lain jika diperlukan setelah penghapusan gagal
              }
            })
            .catch((error) => {
              console.error(error);
              // Handle error jika terjadi
              Swal.fire({
                title: "Error",
                text: "Terjadi kesalahan saat menghapus data",
                icon: "error",
                confirmButtonColor: "#d33",
                confirmButtonText: "OK",
              });

              showModalCpEdit.value = false;
              //redirect ke dashboard
              store.dispatch("element/getDataOld", {
                id_mapel: element.id_mapel,
                id_unit: element.id_unit,
                id_ta: element.id_ta,
                id_unitlevel: element.id_unitlevel,
              });

              router.push({
                name: "admin-mastermapelceklis-form",
                params: {
                  id: element.id_mapel,
                  idunit: element.id_unit,
                  idta: element.id_ta,
                  id_unitlevel: element.id_unitlevel,
                },
              });

              // element.nama_element = '';
            });
        }
      });
    }

    onMounted(async () => {
      await store.dispatch("matapelajaran/getDetail",element.id_mapel);
      await store.dispatch("unitlevel/getDetail",element.id_unitlevel);
      await store.dispatch("element/getDataOld", {
        id_mapel: element.id_mapel,
        id_unit: element.id_unit,
        id_ta: element.id_ta,
        id_unitlevel: element.id_unitlevel,
      });
      await store.dispatch("cp/getData", {
        id_mapel: element.id_mapel,
        id_unit: element.id_unit,
        id_ta: element.id_ta,
        id_unitlevel: element.id_unitlevel,
      });
    });

    const level= computed(() => {
      //panggil getter dengan nama "getParent" di module "kelas" vuex
      return store.getters["unitlevel/getDetail"];
    });

    const mapel= computed(() => {
      //panggil getter dengan nama "getParent" di module "kelas" vuex
      return store.getters["matapelajaran/getDetail"];
    });

    const elements = computed(() => {
      //panggil getter dengan nama "getParent" di module "kelas" vuex
      return store.getters["element/getDataOld"];
    });

    const cps = computed(() => {
      //panggil getter dengan nama "getParent" di module "kelas" vuex
      return store.getters["cp/getData"];
    });

    //return a state and function
    return {
      // <-- state user
      level,
      validation, // <-- state validation
      saveelement,
      savecp,
      saveindikator,
      element,
      elements,
      cp,
      mapel,
      cps,
      indikator,
      showModal,
      closeModal,
      openModal,
      showModalCp,
      closeModalCp,
      openModalCp,
      toggleVisibility,
      isVisible,
      toggleDisable,
      changeElement,
      closeModalElementEdit,
      showModalElementEdit,
      editElement,
      selectedElement,
      updateElement,
      confirmDeleteElement,
      showModalCpEdit,
      editCp,
      updateCp,
      closeModalCpEdit,
      confirmDeleteCp,
      showModalIndikatorEdit,
      editIndikator,
      updateIndikator,
      closeModalIndikatorEdit,
      confirmDeleteIndikator,
      handleSubmit,
    };
  },
};
</script>


<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>
