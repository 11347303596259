import Api from '../../api/Api'
import { useRouter } from "vue-router";

const auth = {

    //set namespace true
    namespaced: true,

    //state
    state: {

        //state untuk token, pakai localStorage, untuk menyimpan informasi tentang token JWT
        token: localStorage.getItem('token') || '',

        //state user, pakai localStorage, untuk menyimpan data user yang sedang login
        user: JSON.parse(localStorage.getItem('user')) || {},
        permission: JSON.parse(localStorage.getItem('permission')) || {},


    },
    //mutations
    mutations: {

        //update state token dan state user dari hasil response
        AUTH_SUCCESS(state, token, user) {
            state.token = token // <-- assign state token dengan response token
            state.user = user // <-- assign state user dengan response data user
        },

        GET_USER(state, user) {
            state.user = user // <-- assign state user dengan response data user
        },

        GET_PERMISSION(state, permission) {
            state.permission = permission // <-- assign state user dengan response data user
        },

        AUTH_LOGOUT(state) {
            state.token = '' // <-- set state token ke empty
            state.user = {} // <-- set state user ke empty array
        }


    },

    //actions
    actions: {

        getUser({ commit }) {
            const router = useRouter();
            const token = localStorage.getItem('token');
            Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            Api.get('/getuser')
                .then(response => {

                    commit('GET_USER', response.data.user);
                    commit('GET_PERMISSION', response.data.permission);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // Token expired or invalid, perform logout and redirect to login
                        commit('AUTH_LOGOUT');
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('permission');
                        delete Api.defaults.headers.common['Authorization'];
                        router.push({ name: 'login' });
                    }
                });
        },

        getPermission({ commit }) {
            const router = useRouter();
            const token = localStorage.getItem('token');
            Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            Api.get('/getuser')
                .then(response => {

                    commit('GET_PERMISSION', response.data.user);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // Token expired or invalid, perform logout and redirect to login
                        commit('AUTH_LOGOUT');
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        delete Api.defaults.headers.common['Authorization'];
                        router.push({ name: 'login' });
                    }
                });
        },

        login({ commit }, user) {

            // //console.log('ini user',user);


            //define callback promise
            return new Promise((resolve, reject) => {

                Api.post('/login', {

                    //data yang dikirim ke server
                    username: user.username,
                    password: user.password,

                })

                    .then(response => {

                        //define variable dengan isi hasil response dari server
                        const token = response.data.token
                        const user = response.data.user
                        const permission = response.data.permission

                        //set localStorage untuk menyimpan token dan data user
                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(user))
                        localStorage.setItem('permission', JSON.stringify(permission))

                        //set default header axios dengan token
                        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                        //commit auth success ke mutation
                        commit('AUTH_SUCCESS', token, user)

                        //commit get user ke mutation
                        commit('GET_USER', user)

                        commit('GET_PERMISSION', permission)

                        //resolve ke component dengan hasil response
                        resolve(response)

                    }).catch(error => {

                        //jika gagal, remove localStorage dengan key token
                        localStorage.removeItem('token')

                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })

        },



        logout({ commit }) {

            //define callback promise
            return new Promise((resolve) => {

                //commit ke mutation AUTH_LOGOUT
                commit('AUTH_LOGOUT')

                //remove value dari localStorage
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('permission')

                //delete header axios
                delete Api.defaults.headers.common['Authorization']

                //return resolve ke component 
                resolve()

            })
        },


    },

    //getters
    getters: {

        //get current user
        currentUser(state) {
            return state.user // <-- return dengan data user
        },

        currentPermission(state) {
            return state.permission // <-- return dengan data user
        },

        //loggedIn
        isLoggedIn(state) {
            return state.token // return dengan data token
        },

    }

}


// **Axios Interceptor** untuk menangani token yang kedaluwarsa
Api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(
    (response) => response,  // Jika response sukses, lanjutkan
    (error) => {
        // Cek jika token kedaluwarsa, langsung logout dan arahkan ke halaman login
       
        if (error.response && error.response.status === 401) {
           

            //remove value dari localStorage
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('permission')

            //delete header axios
            delete Api.defaults.headers.common['Authorization']

            //return resolve ke component 
           
            // Token kadaluwarsa atau tidak valid
          // Arahkan ke halaman login
        }
        return Promise.reject(error);
    }
);


export default auth