<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Laporan Kualitas Pembelajaran</h5>
              <div class="button"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Tahun Ajaran</label>
                  <select class="form-select" v-model="data.ta">
                    <option value="*">Pilih Semester</option>
                    <option v-for="tahunajaran in lista" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3" hidden>
                <div class="form-group">
                  <label for="">Bulan</label>
                  <select class="form-select">
                                        <option value="*">Pilih Bulan</option>
                                        <option
                                            v-for="eks in listbulan"
                                            :key="eks.id"
                                            :value="eks.id"
                                        >
                                            {{ eks.nama }} <span v-if="getexist && getexist.includes(eks.nama)" v-html="'&#x2713;'"></span>
                                        </option>

                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Jenis Laporan</label>
                  <select name="jenis_laporan" class="form-select" v-model="data.jenis_laporan" >
                    <option value="*">Pilih Jenis Laporan</option>
                    <option value="kelas">Kelas</option>
                    <option value="guru">Guru</option>
                    <option value="unit">Unit</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-">
                  <label for="">Aksi</label><br>
                  <a class="btn btn-primary btn-sm" @click="filters" href="#" >Tampilkan Data</a>
                </div>
              </div>
            </div>
            <div class="loader-container" v-if="data.loader">
                  <div class="loader"></div>
            </div>
            <div class="table-responsive">
              <div class="row" v-if="data.lapkelas">
                <div class="row">
                  <div class="col-md-3">
                    <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                    placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  </div>
                  <div class="col-md-3">
                    <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                      class="fa fa-search"></i> </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <table id="datatable-search" class="table table-flush" style="font-size: 12px;width: 80%;">
                      <thead class="thead-light">
                        <tr style="max-height: 5px">
                          <th style="width:35%" class="text-center">Tahun ajaran</th>
                          <th style="width:10%"><center>Kelas</center></th>
                          <th style="width:10%"></th>
                          <th style="width:10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                          
                        <tr v-for="m in kelass.data" :key="m.id" style="height: 1px">
                          <td>{{ m.ta ? m.ta.desc : '' }}</td>
                          <td align="center">{{ m.master_kelas ? m.master_kelas.classname : '' }}</td>
                          <!-- <td>{{ m.wali_kelas ? m.wali_kelas.fullname : '' }} <br> {{ m.guru_pendamping ?
                            m.guru_pendamping.fullname : '' }} </td> -->
                          <!-- <td>{{ m.guru_pendamping ? m.guru_pendamping.total_student_count : '' }} </td> -->
                          <td>
                            <a class="btn btn-success btn-xs" @click="tampilkan" data-type="kelas" :guru-id="m.id_instructor_1 ? m.id_instructor_1 : 0" :data-id="m.idkelas" >View</a>
                          </td>
                          <td>
                            <a :href="`${this.$baseURL}/kppembelajaran/list/${m.idkelas}?&monitoring=1&id=${m.id_instructor_1}&type=kelas&pdf=true`" target="_blank" class="btn btn-xs btn-warning">Export</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                  <div class="col-md-7">
                    
                    <table class="table" >
                      <thead>
                        <tr style="text-align: center;">
                          <th>Unit</th>
                          <th>Kelas</th>
                          <th>Mata Pelajaran</th>
                          <th><center>% Ketuntasan</center></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="m in pelajaran" :key="m.id" style="height: 1px">
                        <td align="center">{{ m.codeunit }}</td>
                        <td align="center">{{ m.namakelas }}</td>
                        <td>{{ m.nama_mapel }}</td>
                        <td align="center">{{ m.ketuntasan }}</td>
                      </tr>
                      </tbody>
                      
                    </table>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                      <div class="card-footer d-flex justify-content-between">
                            <span>
                              <p>Menampilkan {{ kelass.to }} dari {{ kelass.total }}</p>
                            </span>
                            <nav aria-label="Pagination">
                              <ul class="pagination justify-content-end">
                                <li class="page-item" :class="{ disabled: kelass.current_page === 1 }">
                                  <a class="page-link" @click="changePage(kelass.current_page - 1)" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                  </a>
                                </li>
                                <li v-for="page in kelass.links" :key="page"
                                  :class="{ 'page-item': true, 'active': page.active === true }">
                                  <a class="page-link" @click="changePage(page.label)" href="#"
                                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: kelass.current_page === kelass.last_page }">
                                  <a class="page-link" @click="changePage(kelass.current_page + 1)" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                    </div>
                </div>
              </div>
              <div class="row" v-if="data.lapguru">
                <div class="row">
                  <div class="col-md-3">
                    <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchDataGuru"
                    placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  </div>
                  <div class="col-md-3">
                    <button @click="searchDataGuru" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                      class="fa fa-search"></i> </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <table id="datatable-search" class="table table-flush" style="font-size: 12px;width: 80%;">
                      <thead class="thead-light">
                        <tr style="max-height: 5px">
                          <!-- <th style="width:35%" class="text-center">Tahun ajaran</th> -->
                          <th style="width:30%"><center>Nama</center></th>
                          <th style="width:10%"></th>
                          <th style="width:10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                          
                        <tr v-for="m in gurus.data" :key="m.id" style="height: 1px">
                          <!-- <td>{{ m.ta ? m.ta.desc : '' }}</td> -->
                          <!-- <td>{{ m.master_kelas ? m.master_kelas.classname : '' }}</td> -->
                          <td>{{ m.fullname ? m.fullname : '' }}</td>
                          <td>
                            <a class="btn btn-success btn-xs" @click="tampilkan" data-type="guru" :guru-id="m.id ? m.id : 0" :data-id="m.id" >View</a>
                          </td>
                          <td>
                            <a :href="`${this.$baseURL}/kppembelajaran/list/${m.id}?&monitoring=1&id=${m.id}&type=guru&pdf=true`" target="_blank" class="btn btn-xs btn-warning">Export</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>                
                  <div class="col-md-7">
                    
                    <table class="table" >
                      <thead>
                        <tr style="text-align: center;">
                          <th>Unit</th>
                          <th>Kelas</th>
                          <th>Mata Pelajaran</th>
                          <th><center>% Ketuntasan</center></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="m in pelajaran" :key="m.id" style="height: 1px">
                        <td align="center">{{ m.codeunit }}</td>
                        <td align="center">{{ m.namakelas }}</td>
                        <td>{{ m.nama_mapel }}</td>
                        <td align="center">{{ m.ketuntasan }}</td>
                      </tr>
                      </tbody>
                      
                    </table>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                      <div class="card-footer d-flex justify-content-between">
                            <span>
                              <p>Menampilkan {{ gurus.to }} dari {{ gurus.total }}</p>
                            </span>
                            <nav aria-label="Pagination">
                              <ul class="pagination justify-content-end">
                                <li class="page-item" :class="{ disabled: gurus.current_page === 1 }">
                                  <a class="page-link" @click="changePageGuru(gurus.current_page - 1)" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                  </a>
                                </li>
                                <li v-for="page in gurus.links" :key="page"
                                  :class="{ 'page-item': true, 'active': page.active === true }">
                                  <a class="page-link" @click="changePageGuru(page.label)" href="#"
                                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: gurus.current_page === gurus.last_page }">
                                  <a class="page-link" @click="changePageGuru(gurus.current_page + 1)" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                    </div>
                </div>
              </div>
              <div class="row" v-if="data.lapunit">
                <div class="col-md-3" hidden>
                  <div class="form-group">
                    <label for="">Pilih Unit</label>
                    <select class="form-select" v-model="data.unit" @change="changeUnit">
                      <option value="*">Pilih Unit</option>
                      <option v-for="unit in filteredUnits" :key="unit.id" :value="unit.id">
                        {{ unit.code }}
                      </option>
                    </select>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <table id="datatable-search" class="table table-flush" style="font-size: 12px;width: 80%;">
                      <thead class="thead-light">
                        <tr style="max-height: 5px">
                          <th style="width:10%">Unit</th>
                          
                          <th style="width:10%"></th>
                          <th style="width:10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                          
                        <tr v-for="m in filteredUnits" :key="m.id" style="height: 1px">
                          <td>{{ m.code ? m.code : '' }}</td>
                          <td>
                            <a class="btn btn-success btn-xs" @click="tampilkan" data-type="unit" :guru-id="m.id ? m.id : 0" :data-id="m.id" >View</a>
                          </td>
                          <td>
                            <a :href="`${this.$baseURL}/kppembelajaran/list/${m.id}?&monitoring=1&id=${m.id}&type=unit&idmapel=${m.id}&pdf=true`" target="_blank" class="btn btn-xs btn-warning">Export</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>                
                  <div class="col-md-7">
                    
                    <table class="table" >
                      <thead>
                        <tr style="text-align: center;">
                          <th>Unit</th>
                          <th>Kelas</th>
                          <th>Mata Pelajaran</th>
                          <th><center>% Ketuntasan</center></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="m in pelajaran" :key="m.id" style="height: 1px">
                        <td align="center">{{ m.codeunit }}</td>
                        <td align="center">{{ m.namakelas }}</td>
                        <td>{{ m.nama_mapel }}</td>
                        <td align="center">{{ m.ketuntasan }}</td>
                      </tr>
                      </tbody>
                      
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router"; // Correct import from vue-router


export default {
  name: "Kualitaspembelajaranindex",

  computed: {
    filteredUnits() {
      // Kembalikan array yang sudah difilter
      return this.parentmenu.filter(unit => unit.id != 1);
    }
  },

  setup() {
    const router = useRouter();
    const validation = ref([]);
    const store = useStore();
    const data = reactive({
      perPage: 10,
      search: "",
      unit: "",
      id_unit: "",
      unitlevel: "",
      id_unitlevel: "",
      kelas_id: "",
      jenis_semester: "",
      pages: "kualitaspembelajaran-view",
      lapkelas:false,
      lapguru:false,
      loader:false,
      lapunit:false,
      perPageUnit:100,
    });
    
    

    onMounted(async () => {
      await store.dispatch("kkmprogja/getAllkkmprogja");
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch("units/getAll");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("unitlevel/getAll");   
      await store.dispatch("global/getBulanSemester");   
      await store.dispatch("guru/getData");
      await store.dispatch("matapelajaran/getAll");
      await store.dispatch("kkmprogja/getListDataGroup", {
                idkelas: 0,
                id: 0
            });

      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const listprogja = computed(() => {
      return store.getters["kkmprogja/getAllkkmprogja"];
    });

    const focusNextInput = (e, m, index) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-control");
        const currentIndex = Array.from(inputs).indexOf(targetInput);
        const columns = 3; // Number of columns in your table

        // Calculate the next index based on the current column
        const nextIndex = currentIndex + 1 < inputs.length ? currentIndex + 1 : (index + 1) * columns;

        inputs[nextIndex].focus();
      }
    };

    async function updateWeight(e) {
      const inputValue = e.target.value;
      const id = e.target.getAttribute('data-id');

      let formData = new FormData();
      formData.append("kkm_progja", inputValue);
      formData.append("unit_id", id);
      formData.append("_method", "POST");
      console.log("formData "+formData);
      // console.log("id "+id);

      await store
        .dispatch("kkmprogja/update", {
          medicalId: id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    

    const kkmprogja = computed(() => {
      return store.getters["kkmprogja/getAll"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    
    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const kelas = computed(() => {
      return store.getters["kelas/getClassname"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });

    const lista = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const listbulan = computed(() => {
            return store.getters["global/getBulanSemester"];
      });

    setNavPills();
    setTooltip(store.state.bootstrap);

    
  const filters = async () => {
    console.log(data.jenis_laporan);

    // Menyalakan loader agar muncul di UI
    data.loader = true;

    // Gunakan setTimeout untuk menjalankan sisa proses setelah 3 detik
    setTimeout(async () => {
      if (data.jenis_laporan == 'kelas') {
        data.lapguru = false;
        data.lapkelas = true;
        data.lapunit = false;

        let jumlah = data.perPage;
        const pencarian = data.search;

        // Meminta data menggunakan dispatch ke Vuex
        await store.dispatch("kkmprogja/getGuru", {
          page: 1,
          perPage: jumlah,
          search: pencarian,
          tasId: data.ta,
          unit: ''
        });

      } else if (data.jenis_laporan == 'guru') {
        data.lapguru = true;
        data.lapkelas = false;
        data.lapunit = false;

        let jumlah = data.perPage;
        const pencarian = data.search;

        // Meminta data menggunakan dispatch ke Vuex
        await store.dispatch("guru/getData", {
          page: 1,
          perPage: jumlah,
          search: pencarian,
          tasId: data.ta,
          unit: ''
        });

      } else if (data.jenis_laporan == 'unit') {
        data.lapguru = false;
        data.lapkelas = false;
        data.lapunit = true;

        let jumlah = 100;
        const pencarian = data.search;

        // Meminta data menggunakan dispatch ke Vuex
        await store.dispatch("matapelajaran/getData", {
          page: 1,
          perPage: jumlah,
          search: pencarian,
          tasId: data.ta,
          unit: data.unit
        });

      } else if (data.jenis_laporan == '*' || data.jenis_laporan == ' ') {
        alert("Mohon pilih jenis laporan");
      } else {
        alert("belum tersedia");
      }

      // Mematikan loader setelah semua proses selesai
      data.loader = false;
    }, 3000); // Menunda eksekusi selama 3 detik
  };


    const kelass = computed(() => {
      return store.getters["kkmprogja/getGuru"];
    });

    
    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("kkmprogja/getGuru", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("kkmprogja/getGuru", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
      });
    };

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("kkmprogja/getGuru", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,

      });
    };

    async function tampilkan(e) {
      
      const id = e.target.getAttribute('data-id');
      const guruid = e.target.getAttribute('guru-id');
      const type = e.target.getAttribute('data-type');
      data.loader = true;
    
      setTimeout(async () => {
        tampilpelajaran(type,guruid,id);
        data.loader = false;
      }, 3000); 
      console.log(guruid);
      console.log(id);
    }

    const pelajaran = computed(() => {
      return store.getters["kkmprogja/getListDataGroup"];
    });

    const tampilpelajaran = async (type,guruid,id_kelas) => {
      await store.dispatch("kkmprogja/getListDataGroup", {
        idkelas: id_kelas,
        id: guruid,
        type:type,
      });
    };

    const gurus = computed(() => {
      return store.getters["guru/getData"];
    });

    const searchDataGuru = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("guru/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian
      });
      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    const changePerPageGuru = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("guru/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
      });
    };

    const changePageGuru = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("guru/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,

      });
    };

    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const changeUnit = async () => {
      await store.dispatch("matapelajaran/getAll", {
        page: 1,
        perPage: data.perPageUnit,
        search: data.search,
        kelas: data.kelas,
        idunit: data.unit,
        unitlevel: data.level,
      });
      await store.dispatch("unitlevel/getAll", data.unit);
    };

    const unitlist = computed(() => {
      return store.getters["matapelajaran/getAll"];
    });

    return {
      listprogja,
      perPageOptions,
      data,
      updateWeight,
      unitlevels,
      kkmprogja,
      user,
      focusNextInput,
      kelas,
      tas,
      lista,
      listbulan,
      filters,
      kelass,
      searchData,
      tampilkan,
      tampilpelajaran,
      pelajaran,
      changePerPage,
      changePage,
      gurus,
      searchDataGuru,
      changePerPageGuru,
      changePageGuru,
      parentmenu,
      changeUnit,
      unitlist
    };
  },
};
</script>
<style scoped>
.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid green; /* Green */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Centering the loader */
.loader-container {
    display: flex; /* Use flexbox to center the loader */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full height of the viewport */
    width: 100vw;  /* Full width of the viewport */
    position: fixed; /* Stay fixed at the center */
    top: 0; /* Ensure the loader container starts at the top */
    left: 0; /* Ensure the loader container starts at the left */
    background-color: rgba(255, 255, 255, 0.8); /* Optional: light background to obscure content */
    z-index: 9999; /* Make sure it stays on top of other content */
}
        
</style>