<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Peserta Ekskul </h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">

                <div class="row">

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Tahun Ajaran<small></small></label>
                      <select class="form-control" v-model="menu.id_ta" readonly disabled>
                        <option value="">-- Pilih Tahun Ajaran --</option>
                        <option v-for="parent in tahunajaran" :key="parent.id" :value="parent.id">
                          {{ parent.desc }}
                        </option>
                      </select>
                      <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                    </div>
                  </div>
                  <!-- {{  ekskulall }} -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Nama Ekskul<small></small></label>
                      <!-- <select class="form-control" v-model="menu.id_ekskul" @change="changeUnit" readonly disabled>
                          <option value="">-- Pilih Ekskul --</option>
                          <option
                            v-for="eks in ekskulall.data"
                            :key="eks.id"
                            :value="`${eks.id} ${eks.unit.id}`"
                          >
                            {{ eks.nama_ekskul }} ({{ eks.unit.code }})
                          </option>
                        </select> -->
                      <br>
                      <input type="text" :value="eskul.nama_ekskul" class="form-control" disabled>
                      <small class="text-danger" v-if="validation.id_ekskul">{{ validation.id_ekskul[0] }}</small>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Nama Koordinator</label>
                      <select class="form-control" v-model="menu.id_instructors" readonly disabled>
                        <option value="">-- Pilih Koordinator --</option>
                        <option v-for="guru in gurus" :key="guru.id" :value="guru.id">{{ guru.fullname }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.id_instructors">{{ validation.id_instructors[0]
                        }}</small>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Nama Pelatih</label>
                      <input type="text" placeholder="Nama Pelatih" class="form-control" v-model="menu.nama_pelatih"
                        readonly disabled />
                      <small class="text-danger" v-if="validation.nama_pelatih">{{
                        validation.nama_pelatih[0]
                      }}</small>
                    </div>
                  </div>

                  <div class="col-md-3" hidden>
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Jumlah Peserta Minimal</label>
                      <input type="number" placeholder="0" class="form-control" v-model="menu.jumlah_peserta_minimal"
                        readonly disabled />
                      <small class="text-danger" v-if="validation.jumlah_peserta_minimal">{{
                        validation.jumlah_peserta_minimal[0]
                      }}</small>
                    </div>
                  </div>

                  <div class="col-md-3" hidden>
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Jumlah Peserta Maksimal</label>
                      <input type="number" placeholder="99999" class="form-control"
                        v-model="menu.jumlah_peserta_maksimal" readonly disabled />
                      <small class="text-danger" v-if="validation.jumlah_peserta_maksimal">{{
                        validation.jumlah_peserta_maksimal[0]
                      }}</small>
                    </div>
                  </div>

                  <div class="col-md-6 justify-content-right" hidden>
                    <div class="form-group">
                      <label for="exampleFormControlInput1"> Peserta Kelas </label>
                      <br>
                      <div v-for="m in menu.namaunitlevel.data" :key="m" style="float: left;margin: 5px;">
                        <b class="badge bg-info mr-2"> {{ m }} </b>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4" hidden>
                    <div class="form-group">

                      <label for="exampleFormControlSelect2">Unitlevel {{ SiswaAktifWhereIn }}</label>
                      <select2-multiple-control v-model="menu.id_unit_level" :options="SiswaAktifWhereIn"
                        :settings="{ settingOption: value, settingOption: value }" />


                      <!-- <select class="form-control select2" id="exampleFormControlSelect2"
                          v-model="jadwalpelaksanaan.unitlevel">
                          <option v-for="u in unitlevels" :key="u.id" :value="u.id">{{ u.name }}</option>
                        </select> -->
                      <small class="text-danger" v-if="validation.unitlevel">{{ validation.unitlevel[0] }}</small>

                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row justify-content-between ">
                      <div class="col-md-2">
                        <div class="d-flex justify-content-between align-items-center">
                          <span>Show: </span>
                          <select v-model="data.perPage" class="form-control form-control-sm ml-2"
                            @change="changePerPage">
                            <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-10 d-flex mb-3">
                        <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                          placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                        <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                          <i class="fa fa-search"></i> </button>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                        <thead class="thead-light">
                          <tr style="max-height: 5px">
                            <th class="text-center">Nama Siswa</th>
                            <th class="text-center">Kelas</th>
                            <th class="text-center">#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="m in siswas.data" :key="m.id" style="height: 1px">
                            <td>{{ m.fullname }}</td>
                            <td class="text-center">{{ m.classname }}</td>
                            <td class="text-center">
                              <button type="button" class="btn btn-success btn-xs m-0"
                                @click="addPengajar(m.id_student)"> <i class="fas fa-plus"></i> </button>
                              <!-- <router-link :to="{name: 'admin-bebanpengajar-listsiswa', params:{id: m.id }}" class="btn btn-success btn-xs m-0 me-2"> <i class="fa fa-plus"></i> </router-link>
                              <button class="btn btn-danger btn-xs m-0" @click="cancelPengajar(m.id)"> <i class="fas fa-minus"></i> </button> -->
                              <!-- <button class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.id)"> <i class="fas fa-trash"></i> </button> -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card-footer d-flex justify-content-between">
                        <span>
                          <p>Menampilkan {{ siswas.to }} dari {{ siswas.total }} </p>
                        </span>
                        <nav aria-label="Pagination">
                          <ul class="pagination justify-content-end">
                            <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                              <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#"
                                aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li v-for="page in siswas.links" :key="page"
                              :class="{ 'page-item': true, 'active': page.active === true }">
                              <a class="page-link" @click="changePage(page.label)" href="#"
                                v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{
                        page.label }}</a>
                            </li>
                            <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                              <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#"
                                aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="table-responsive">
                      <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                        <thead class="thead-light">
                          <tr style="max-height: 5px">
                            <th>Nama Siswa</th>
                            <th>Kelas</th>
                            <th>#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="m in list.data" :key="m.idkey" style="height: 1px">
                            <td>{{ m.fullname }}</td>

                            <td>{{ m.classname }}</td>

                            <td>
                              <button class="btn btn-danger btn-xs m-0" @click="cancelPengajar(m.idkey)"> <i
                                  class="fas fa-minus"></i> </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="card-footer d-flex justify-content-between">
                        <span>
                          <p>Menampilkan {{ list.to }} dari {{ list.total }} </p>
                        </span>
                        <nav aria-label="Pagination">
                          <ul class="pagination justify-content-end">
                            <li class="page-item" :class="{ disabled: list.current_page === 1 }">
                              <a class="page-link" @click="changePage(list.current_page - 1)" href="#"
                                aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li v-for="page in list.links" :key="page"
                              :class="{ 'page-item': true, 'active': page.active === true }">
                              <a class="page-link" @click="changePage(page.label)" href="#"
                                v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{
                        page.label }}</a>
                            </li>
                            <li class="page-item" :class="{ disabled: list.current_page === list.last_page }">
                              <a class="page-link" @click="changePage(list.current_page + 1)" href="#"
                                aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="row">
                  <div class="col-12">
                    <!-- <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button> -->
                    &nbsp;
                    <router-link :to="{ name: 'admin-ekskulaktif' }" class="btn btn-danger btn-sm">kembali</router-link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import Select2MultipleControl from 'v-select2-multiple-component';

export default {
  name: "MenuIndex",
  components: {
    Select2MultipleControl
  },
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.eksulid = this.$route.params.id;
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const menu = reactive({
      id_ta: "",
      id_ekskul: "",
      level: "",
      id_instructors: "",
      nama_pelatih: "",
      jumlah_peserta_minimal: "",
      jumlah_peserta_maksimal: "",
      namaunitlevel: "",
      id_unit_level: [],
    });

    const data = reactive({
      // currentPage: 1,
      perPage: 30,
      search: '',
      kelas: '',
      unitlevel: '',
      level: '',
      unit: '',
      pages: 'ekskulaktif-view'

    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];
    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/getSiswaAktifin", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
        id_unit_level: menu.level,
        id_ekskul_aktif: route.params.id
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/getSiswaAktifin", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level,
        id_unit_level: menu.level,
        id_ekskul_aktif: route.params.id
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/getSiswaAktifin", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        id_unit_level: menu.level,
        id_ekskul_aktif: route.params.id
      });
    }
    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updateMenu() {
      //define variable
      let id_ta = menu.id_ta;
      let id_ekskul = menu.id_ekskul;
      let level = menu.level;
      let id_instructors = menu.id_instructors;
      let nama_pelatih = menu.nama_pelatih;
      let jumlah_peserta_minimal = menu.jumlah_peserta_minimal;
      let jumlah_peserta_maksimal = menu.jumlah_peserta_maksimal;

      let formData = new FormData();

      formData.append("id_ta", id_ta);
      formData.append("id_ekskul", id_ekskul);
      formData.append("level", level);
      formData.append("id_instructors", id_instructors);
      formData.append("nama_pelatih", nama_pelatih);
      formData.append("jumlah_peserta_minimal", jumlah_peserta_minimal);
      formData.append("jumlah_peserta_maksimal", jumlah_peserta_maksimal);
      formData.append("_method", "PUT");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("ekskulaktif/update", {
          menuId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-ekskulaktif" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }



    watch(
      () => store.state.ekskulaktif.detailmenu,
      (newMenu) => {
        if (newMenu) {
          const {
            id_ta,
            id_ekskul,
            id_unit,
            unitlevel,
            id_instructors,
            nama_pelatih,
            jumlah_peserta_minimal,
            jumlah_peserta_maksimal,
            namaunitlevel,


          } = newMenu;
          menu.id_ta = id_ta;
          menu.id_ekskul = id_ekskul + ' ' + id_unit;
          menu.level = JSON.parse(unitlevel);
          menu.id_instructors = id_instructors;
          menu.nama_pelatih = nama_pelatih;
          menu.jumlah_peserta_minimal = jumlah_peserta_minimal;
          menu.jumlah_peserta_maksimal = jumlah_peserta_maksimal;
          menu.namaunitlevel = namaunitlevel;
        }
      }
    );

    //computed
    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const tahunajaran = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["tahunajaran/getAll"];
    });

    const ekskulall = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["ekskul/getDataAll"];
    });


    const changeUnit = async () => {
      const selectedValues = menu.id_ekskul.split(' ');
      // const eksId = selectedValues[0];
      const unitId = selectedValues[1];
      await store.dispatch("unitlevel/getAll",
        unitId
      );

      // console.log(eksId);
      // console.log(unitId);
    };

    const levelUnit = async () => {
      const selectedValues = menu.id_ekskul.split(' ');
      // const eksId = selectedValues[0];
      const unitId = selectedValues[1];
      await store.dispatch("unitlevel/getAll",
        unitId
      );

      // console.log(eksId);
      // console.log(unitId);
    };

    const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAll"];
    });

    const gurus = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["guru/getAll"];
    });

    const keypermission = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["permission/getAllPermission"];
    });

    const SiswaAktifWhereIn = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      // const unitId = [11,12];
      const unitId = [11, 12];
      return store.getters["siswa/getSiswaAktifWhereIn", unitId
      ];
    });

    // const SiswaAktifWhereIn = async () => {
    //   // const selectedValues = menu.id_ekskul.split(' ');
    //   // const eksId = selectedValues[0];
    //   const unitId = [11,12];

    //   await store.dispatch("siswa/getSiswaAktifWhereIn",
    //     {
    //       id_unit_level: unitId,
    //     }
    //   );

    //   // console.log(eksId);
    //   // console.log(unitId);
    // };

    const formattedUnitlevels = computed(() => {
      // return 0;
      //panggil getter dengan nama "getParent" di module "menu" vuex
      // console.log("ini konsole ",SiswaAktifWhereIn);
      return SiswaAktifWhereIn;
      // return SiswaAktifWhereIn.value.map((item) => ({
      //   id: item.id,
      //   text: item.text, // Show name if exists, otherwise an empty string
      // }));
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaAktifin"];
    });


    const list = computed(() => {
      return store.getters["ekskulaktif/getPesertabaru"];
      // return 0;
    });

    const eskul = computed(() => {
      return store.getters["ekskulaktif/getDetailMenu"];
      // return 0;
    });


    onMounted(async () => {
      await store.dispatch("units/getAll");
      await store.dispatch("permission/getAllPermission");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("ekskul/getDataAll");
      await store.dispatch('guru/getAll')
      await store.dispatch("unitlevel/getAll");
      await store.dispatch("ekskulaktif/getDetailMenu", route.params.id);
      levelUnit();
      await store.dispatch("siswa/getSiswaAktifin", {
        page: 1,
        perPage: data.perPage,
        pencarian: 'A',
        id_ekskul_aktif: route.params.id
      });
      // await store.dispatch("siswa/getSiswaAktif");
      await store.dispatch("ekskulaktif/getPesertabaru", route.params.id);
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }

    });


    async function addPengajar(id) {

      let formData = new FormData();
      formData.append('id_ekskul_aktif', route.params.id)
      formData.append('students_id', id)
      console.log('ini form', formData);
      await store.dispatch('ekskulaktif/storepeserta', formData)
        .then(() => {
          Swal.fire({
            title: "BERHASIL!",
            text: "Data Berhasil Ditambahkan!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });

          // store.dispatch("pengajar/getData");
          store.dispatch("ekskulaktif/getPesertabaru", route.params.id);
        })
        .catch((error) => {
          validation.value = error;
          Swal.fire({
            title: "GAGAL!",
            text: validation.value.error,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    }

    function cancelPengajar(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('ekskulaktif/destroypeserta', id)
            .then(() => {
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })
              store.dispatch("ekskulaktif/getPesertabaru", route.params.id);
            })
        }
      });
    }


    //return a state and function
    return {
      menu, // <-- state user
      validation, // <-- state validation
      updateMenu,
      parentmenu, // <-- hook toast
      keypermission,
      tahunajaran,
      ekskulall,
      changeUnit,
      unitlevels,
      gurus,
      levelUnit,
      formattedUnitlevels,
      SiswaAktifWhereIn,
      siswas,
      data,
      datapermissions,
      perPageOptions,
      changePage,
      changePerPage,
      searchData,
      addPengajar,
      cancelPengajar,
      list,
      eskul
    };
  },
};
</script>