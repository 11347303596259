import Api from '../../api/Api'
const ekskul = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        menus: [],
        datamenus: [],
        cekusers:[],
        parent:[],
        unit:[],
        detailmenu:{},
        ekskulall:[],
        detailpeserta:[],
        detailpesertabaru:[],
        detailpredikat:[],
        detailpesertabarunilai:[],
    },

    //mutations
    mutations: {
        GET_MENU(state, menus) {
            state.menus = menus // <-- assign state orders dari hasil response
        },
        SET_MENU(state, datamenus) {
            state.datamenus = datamenus
        },
        SET_CEKUSER(state, cekusers) {
            state.cekusers = cekusers
        },
        SET_PARENT(state, parent) {
            state.parent = parent
        },
        SET_UNIT(state, unit) {
            state.unit = unit
        },
        GET_DETAIL_MENU(state,  detailmenu) {
            state. detailmenu =  detailmenu
        },

        SET_EKSULALL(state, ekskulall) {
            state.ekskulall = ekskulall
        },

        GET_DETAIL_PESERTA(state,  detailpeserta) {
            state. detailpeserta =  detailpeserta
        },

        GET_DETAIL_PESERTA_BARU(state,  detailpesertabaru) {
            state. detailpesertabaru =  detailpesertabaru
        },

        GET_DETAIL_PREDIKAT(state,  detailpredikat) {
            state. detailpredikat =  detailpredikat
        },

        GET_DETAIL_PESERTA_BARU_NILAI(state,  detailpesertabarunilai) {
            state. detailpesertabarunilai =  detailpesertabarunilai
        },
      
    },

    _actions: {
        getMenu({ commit }) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get('/ekskul/getperuser')
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_MENU', response.data.data)

                })

        },

        getUnit({ commit }) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get('/unit/getall')
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_UNIT', response.data.data)

                })

        },

        getDetailMenu({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ekskul-aktif/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL_MENU', response.data.data)

                })

        },
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let id_ta = payload ? payload.id_ta : ''
            let unit_id = payload ? payload.unit_id : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/prestasi/siswa?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&id_ta=${id_ta}&unit_id=${unit_id}`)
                .then(response => {
                    //console.log('ini parent',response.data.data)
                    commit('SET_MENU', response.data.data)
                })
        },

        CekUser({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ekskul-aktif?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&user=true`)
                .then(response => {
                    //console.log('ini parent',response.data.data)
                    commit('SET_CEKUSER', response.data.data)
                })
        },

        getDataAll({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ekskul-aktif?pencarian=${pencarian}`)
                .then(response => {
                    //console.log('ini parent',response.data.data)
                    commit('SET_EKSULALL', response.data.data) 
                })
        },

        getParent({ commit }) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get('/ekskul/getparent')
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_PARENT', response.data.data)

                })

        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.delete(`/ekskul-aktif/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_MENU
                        commit('SET_MENU', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, menu) {
            // //console.log('ini menu', menu);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

            
                Api.post('/prestasi/siswa', menu)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { menuId, payload }) {

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

            
                Api.post(`/ekskul-aktif/${menuId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        storepeserta({ commit }, { payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/prestasi/siswa/showraport`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getPeserta({ commit }, payload) {
            // console.log('ini parsing',payload);
          
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/prestasi/siswa/${payload}`)
                .then(response => {
                    // console.log('ini list data',response.data.data)
                    commit('GET_DETAIL_PESERTA', response.data.data)
                })
        },

        

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getMenu(state) {
            return state.menus
        },

        getUnit(state) {
            return state.unit
        },


        getParent(state) {
            return state.parent
        },

        getData(state) {
            return state.datamenus
        },

        CekUser(state) {
            return state.cekusers
        },

        getDetailMenu(state){
            return state.detailmenu
        },

        getDataAll(state) {
            return state.ekskulall
        },

        getPeserta(state) {
            return state.detailpeserta
        },

        getPesertabaru(state) {
            return state.detailpesertabaru
        },

        getpredikat(state){
            return state.detailpredikat
        },

        getPesertabaruNilai(state) {
            return state.detailpesertabarunilai
        },
    }

}

export default ekskul