<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
  
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Data Rekap Laporan Ekskul Siswa
                </h5>
                <router-link to="/perwalian" class="btn btn-danger btn-sm">Kembali</router-link
              >
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between ">
                <div class="col-md-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Show: </span>
                    <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                      <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                  <input type="text" class="form-control mr-2"  v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  <button  @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i class="fa fa-search"></i> </button>
                </div>
              </div>
              <div class="row">
                <div class="row">
                  
                </div>
              </div>
              <div class="table-responsive">
                <table
                  id="datatable-search"
                  class="table table-flush small-table"
                  style="font-size: 12px"
                >
                  <thead class="thead-light">
                    <tr>
                        <th colspan="4" class="text-center">
                            <div v-if="kelas">
                              Rekap Ekskul Kelas {{ kelas.master_kelas ? kelas.master_kelas.classname:'' }} Tahun Ajaran {{ ta.name }}
                            </div>
                            <div v-else>
                              Loading...
                            </div>
                        </th>
                    </tr>
                    <tr style="max-height: 5px">
                      <th rowspan="2">Nama Siswa</th>
                      <th class="text-center" rowspan="2">Nama Ekskul</th>
                      <th class="text-center" rowspan="2">Predikat</th>
                      <th class="text-center" rowspan="2">Deskripsi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in siswas.data" :key="m.id" style="height: 1px">
                      <td>{{ m.fullname }}</td>
                      <td class="text-center">{{ m.nama_ekskul }}</td>
                      <td class="text-center" v-if="m.nama_ekskul != null">
                                            <select class="form-control" 
                                                :id-student="m.id"
                                                :data-id="m.id"
                                                :id-ekskul="m.id_ekskul_aktif"
                                                :key="m.id" @change="savepredikat">
                                                <option value="0">-- Pilih --</option>
                                                <option
                                                  v-for="eks in predikat"
                                                  :key="eks.id"
                                                  :value="`${eks.id}`"
                                                  :selected="eks.id == m.id_predikat"
                                                >
                                                  {{ eks.nama_predikat_capaian }}
                                                </option>
                                              </select>                            
                        <!-- {{ m.nama_predikat_capaian }} -->
                      </td>
                      <td v-else>
                        
                      </td>
                      <td class="text-left" style="white-space: normal;text-align: left;" v-if="m.nama_ekskul != null">
                                                <textarea 
                                                  name="deskripsi" 
                                                  :id="m.id"
                                                  :id-predikat="m.id"
                                                  :id-ekskul="m.id_ekskul_aktif"
                                                  :key="m.id" 
                                                  :id-student="m.id"
                                                  :value="m.description" 
                                                  cols="60" 
                                                  rows="3" 
                                                  maxlength="200"
                                                  style="width: 100%;"
                                                  @input="handleKeyUp"
                                              ></textarea>
                        <!-- {{ m.description }} -->
                      </td>
                      <td v-else>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
             
            </div>
  
            <div class="card-footer d-flex justify-content-between">
              <span>
                <p>Menampilkan {{siswas.to}} dari {{siswas.total}}  </p>
              </span>
              <nav aria-label="Pagination">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                    <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li v-for="page in siswas.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                  >
                    <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                    <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>


  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { computed, onMounted, reactive ,ref} from "vue";
  import { useStore } from "vuex";
  import Swal from 'sweetalert2';
  import {  useRoute, useRouter } from "vue-router";
  
  export default {
    name: "siswaIndex",
  
    setup() {
      const store = useStore();
      const data = reactive({
        // currentPage: 1,
        perPage:10,
        search:'',
        kelas:'',
        unitlevel:'',
        level:'',
        unit:''
        
      });
  
      const router = useRouter();

      const route = useRoute();
  
      

      const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });
  
      const siswas = computed(() => {
        return store.getters["siswa/getSiswaNilaiSikapEkskul"];
      });

      const units = computed(() => {
        return store.getters["units/getAll"];
      });

      const unitlevel = computed(() => {
        return store.getters["unitlevel/getAll"];
      });

       
      const ta = computed(() => {
        return store.getters["tahunajaran/getActive"];
      });

      const kelas = computed(() => {
        return store.getters["kelas/getDetail"];
      });

      


      
      const perPageOptions = [5, 10, 15, 25, 50, 100];
  
  
      const changePage = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page:page,
          perPage:jumlah,
          search: pencarian,
          kelasId:route.params.id,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
  
        });
      };
  
      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          kelasId:route.params.id,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });
      };

      const changeUnit = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          kelasId:route.params.id,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });

        await store.dispatch("unitlevel/getAll",  data.unit);
      };

      const changeUnitlevel = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          kelasId:route.params.id,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });

        await store.dispatch("masterkelas/getAll",  data.level);
      };

      const changeKelas = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          kelasId:route.params.id,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });

      
      };


  
      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          kelasId:route.params.id,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });
       
  
    // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      function confirmDelete(id) {
        Swal.fire({
          title: 'Konfirmasi',
          text: 'Apakah Anda yakin ingin menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('siswa/destroy', id)
                  .then(() => {
  
                    store.dispatch("siswa/getSiswaNilaiSikapEkskul");
                    router.push({ name: "admin-siswa" });
                    //alert
                    Swal.fire({
                      title: 'BERHASIL!',
                      text: "Data Berhasil Dihapus!",
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 2000
                    })
  
                  })
              }
        });
      }

      const predikat = computed(() => {
            return store.getters["ekskulaktif/getpredikatRekap"];
            // return 0;
      });

       //validation state
      const validation = ref([]);

    async function savepredikat(event) {
      const id_student = event.target.getAttribute('id-student');
      const id_predikat = event.target.value;
      const id_ekskul   = event.target.getAttribute('id-ekskul');

      let formData = new FormData();

      formData.append("id_student", id_student);
      formData.append("id_predikat", id_predikat);
      formData.append("id_ekskul_aktif", id_ekskul);
      formData.append("typenilai", 'predikat');
        console.log("Form data ",formData);
      
                          await store.dispatch('ekskulaktif/penilaianstore', formData)
                            .then(() => {

                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
    }

    async function handleKeyUp(event) {
      const id_student = event.target.getAttribute('id-student');
      const id_predikat = event.target.getAttribute('id-predikat');
      const description = event.target.value !== '' ? event.target.value : document.getElementById(id_student).value;
      const id_ekskul   = event.target.getAttribute('id-ekskul');

      let formData = new FormData();


      formData.append("id_student", id_student);
      formData.append("id_predikat", id_predikat);
      formData.append("description", description);
      formData.append("id_ekskul_aktif", id_ekskul);
      formData.append("typenilai", 'desription');
      // console.log(formData);
      
                          await store.dispatch('ekskulaktif/penilaianstore', formData)
                            .then(() => {

                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
    }

  
      onMounted(async () => {
        await store.dispatch("siswa/getSiswaNilaiSikapEkskul", {
          page:1,
          perPage:10,
          search:'',
          kelasId:route.params.id,
          unit:'',
          unitlevel:'',
          guru:route.params.id,
        });
        
        await store.dispatch("units/getAll");
        await store.dispatch("unitlevel/getAll");
        await store.dispatch("tahunajaran/getActive");
        await store.dispatch("user/getDataPermission");
        await store.dispatch("kelas/getDetail",route.params.id);
        await store.dispatch("ekskulaktif/getpredikatRekap",route.params.id);
        // console.log("sasasa ", kelas.value.master_kelas.unit_level.id_unit);
        
      });

      // Memanggil fungsi async untuk mengambil detail kelas
      // const fetchData = async () => {
      //     await store.dispatch("kelas/getDetail", route.params.id);
          
      //     // Memastikan kelas dan master_kelas ada sebelum mengakses unit_level.id_unit
      //     if (kelas.value && kelas.value.master_kelas && kelas.value.master_kelas.unit_level) {
      //         const idUnit = kelas.value.master_kelas.unit_level.id_unit;
      //         await store.dispatch("ekskulaktif/getpredikat", idUnit);
      //     } else {
      //         // await store.dispatch("ekskulaktif/getpredikat", 2);
      //         console.error("Data kelas atau master_kelas tidak tersedia.");
      //     }
      // };

      // // Panggil fungsi fetchData saat komponen di-mount
      // onMounted(fetchData);


      setNavPills();
      setTooltip(store.state.bootstrap);
  
      return {
        siswas,
        changePage,
        changePerPage,
        perPageOptions,
        data,
        searchData,
        confirmDelete,
        units,
        unitlevel,
        changeUnit,
        changeUnitlevel,
        changeKelas,
        datapermissions,
        ta,
        kelas,
        predikat,
        savepredikat,
        handleKeyUp
      };
    },
  };
  </script>