<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Tambah Master P5 </h5>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="savemasterp5">
<!--                   
  
                    <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="">Unit</label>
                            <select
                            class="form-control select2"
                            v-model="masterp5.id_unit"
                            @change="changeUnit"
    
                            >
                              <option value="">-- select Unit--</option>
                              <option
                                v-for="u in units"
                                :key="u.id"
                                :value="u.id"
                              >
                                {{ u.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div> 

                      <div class="row">
                        <div class="col-">
                          <div class="form-group">
                            <label for="exampleFormControlSelect2">Unitlevel</label>
                            <select class="form-control select2" id="exampleFormControlSelect2"
                              v-model="masterp5.id_unitlevel">
                              <option v-for="u in unitlevels" :key="u.id" :value="u.id">{{ u.name }}</option>
                            </select>
                            <small class="text-danger" v-if="validation.unitlevel">{{ validation.unitlevel[0] }}</small>
                           
                          </div>
                        </div>
                      </div>
   -->
                    
                    <!-- <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1"
                            >Tahun Ajaran <span class="text-danger">*</span></label
                          >
                          <select class="form-control" v-model="masterp5.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{ tahunajaran.desc}}</option>
                        </select>
                          <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                        </div>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Proyek</label>
                          <input
                            type="text"
                            placeholder="Proyek"
                            class="form-control"
                            v-model="masterp5.proyek"
                          />
                          <small class="text-danger" v-if="validation.proyek">{{
                            validation.proyek[0]
                          }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Tema</label>
                          <input
                            type="text"
                            placeholder="Tema"
                            class="form-control"
                            v-model="masterp5.tema"
                          />
                          <small class="text-danger" v-if="validation.tema">{{
                            validation.tema[0]
                          }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Nama</label>
                          <input
                            type="text"
                            placeholder="Nama "
                            class="form-control"
                            v-model="masterp5.nama"
                          />
                          <small class="text-danger" v-if="validation.nama">{{
                            validation.nama[0]
                          }}</small>
                        </div>
                      </div>
                    </div>                

                   
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Deskripsi</label>
                          <textarea class="form-control"  v-model="masterp5.deskripsi">
                           
                          </textarea>
                          <small class="text-danger" v-if="validation.deskripsi">{{
                            validation.deskripsi[0]
                          }}</small>
                        </div>
                      </div>
                    </div>

                    
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                      
                        <router-link
                          :to="{ name: 'admin-masterp5',params:{kelasid:$route.params.kelasid}}"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                 
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
  import Swal from 'sweetalert2';
  
  export default {
    name: "kelasIndex",
    components: {},
    data() {
      return {
        showkelas: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      const route = useRoute();
      //user state
      const masterp5 = reactive({
      id_unit:"",
        id_ta: "",
        tema:"",
        deskripsi:"",
        nama:"",
        proyek:"",
        id_unitlevel: route.params.id
    });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();

      
  
      //function register, fungsi ini di jalankan ketika form di submit
      function savemasterp5() {
       
       //define variable
     
  
       let formData = new FormData();
  
        
          formData.append('id_unitlevel',this.masterp5.id_unitlevel)
          formData.append('nama', this.masterp5.nama)
          formData.append('tema', this.masterp5.tema)
          formData.append('proyek', this.masterp5.proyek)
          formData.append('deskripsi', this.masterp5.deskripsi)
        
       formData.append("_method", "POST");
       //panggil actions "register" dari module "auth"
       store
         .dispatch("masterp5/store", 
           formData,
         )
         .then(() => {
           //redirect ke dashboard
           router.push({ name: "admin-masterp5", params:{kelasid:route.params.kelasid} });
           Swal.fire({
           icon: 'success',
           title: 'Tambah data berhasil',
         });
  
           // toast.success("Tambah data berhasil")
         })
         .catch((error) => {
           //show validaation message
           // //console.log(error);
  
           validation.value = error;
         });
     }
  
  
      onMounted(async () => {
        await store.dispatch('tahunajaran/getAll'),
        await store.dispatch("units/getAll");
        await store.dispatch("unitlevel/getAll");
      });
  
      //computed
      const tahunajarans = computed(() => {
        //panggil getter dengan nama "getParent" di module "kelas" vuex
        return store.getters["tahunajaran/getAll"];
      });

      const changeUnit = async () => {
      await store.dispatch("unitlevel/getAll",
        masterp5.id_unit
      );
    };

    const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAll"];
    });

      const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["units/getAll"];
    });
  
     
  
   
      //return a state and function
      return {
        masterp5, // <-- state user
        validation, // <-- state validation
        savemasterp5,
        tahunajarans,
        units,
        changeUnit,
        unitlevels
      };

    },
  };
  </script>