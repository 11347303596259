<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
  
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Data Page break p5</h5>
                <div class="button">
                  
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row my-3">
                <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.id_ta" @change="changeTa">
                    <option value="">-- Pilih Tahun Ajaran --</option>
                    <option v-for="unit in tas" :key="unit.id" :value="unit.id">
                      {{ unit.desc }}
                    </option>
                  </select>
                </div>
              </div>
                <div class="col-md-3 my-auto mr-5">
                  <div class="input-group mb-3">
                    <select class="form-control select2" v-model="data.unit_id" @change="changeUnit">
                      <option value="">-- Pilih Unit --</option>
                      <option v-for="unit in units" :key="unit.id" :value="unit.id">
                        {{ unit.name }}
                      </option>
                    </select>
                  </div>
                </div>
               
               
              </div>
              <div class="row justify-content-between ">
                <div class="col-md-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Show: </span>
                    <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                      <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                  <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                    placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                      class="fa fa-search"></i> </button>
                </div>
              </div>
              <div class="table-responsive">
                <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                  <thead class="thead-light">
                    <tr style="max-height: 5px">
                    
                      <th>Tahun Ajaran</th>
                      <th>Unit</th>
                      <th>Level</th>
                      <th>Pagebreak</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in menus.data" :key="m.id" style="height: 1px">
                     
                      <td>{{ m.desc }}</td>
                      <td>{{ m.nama_unit }}</td>
                      <td>{{ m.nama_unitlevel }}</td>
                     
                      <td align="center">
                        <argon-switch
                            type_pagebreak="catatanp5"
                             :id_unit="`${m.id_unit}`"
                              :id_unitlevel="`${m.id}`"
                                :id_ta="`${m.id_ta}`"
                             :checked="getStatus(m.idta, m.id_unit, m.id) == '1'"
                            @change="handleSwitchChange"
                            :disabled="true"
                        >
                        </argon-switch>
  
                      </td>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
  
  
            </div>
  
            <div class="card-footer d-flex justify-content-between">
              <span>
                <p>Menampilkan {{ menus.to }} dari {{ menus.total }}</p>
              </span>
              <nav aria-label="Pagination">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: menus.current_page === 1 }">
                    <a class="page-link" @click="changePage(menus.current_page - 1)" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li v-for="page in menus.links" :key="page"
                    :class="{ 'page-item': true, 'active': page.active === true }">
                    <a class="page-link" @click="changePage(page.label)" href="#"
                      v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: menus.current_page === menus.last_page }">
                    <a class="page-link" @click="changePage(menus.current_page + 1)" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { computed, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Swal from 'sweetalert2';
  import { useRouter } from "vue-router";
  import ArgonSwitch from "@/components/ArgonSwitch.vue";
  import { ref } from "vue";
  
  export default {
    name: "PrestasiSiswaIndex",
    components: {
        ArgonSwitch,
    },
  
    setup() {
      const validation = ref([]);
      const store = useStore();
      const data = reactive({
        // currentPage: 1,
        perPage: 10,
        id_ta:'',
        unit_id:'',
        search: '',
        loading: '',
        pages: 'pagebreakp5-view'
      });
  
      const router = useRouter();
  
      const getUser = computed(() => {
        return store.getters["auth/getUser"];
      });
  
  
      const units = computed(() => {
        return store.getters["units/getAll"];
      });
  
      const tas = computed(() => {
        return store.getters["tahunajaran/getAll"];
      });
  
      onMounted(async () => {
        await store.dispatch("units/getAll");
        await store.dispatch("tahunajaran/getAll");
        await store.dispatch("global/getPagebreakCatatan");
        await store.dispatch("global/getPagebreakCatatan");
        await store.dispatch("global/getPagebreak");
        await store.dispatch("user/getDataPermission");
        const permissions = localStorage.getItem('permission');
  
        if (!permissions.includes(data.pages)) {
          router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
        }
      });

      const pagebreak = computed(() => {
        return store.getters["global/getPagebreak"];
      });
  
      const menus = computed(() => {
        return store.getters["global/getPagebreakCatatan"];
      });
  
      const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });

      const getStatus = (idTa, idUnit, idUnitlevel) => {
      if (pagebreak.value && pagebreak.value.data) {
          const nilai = pagebreak.value.data.find((item) => {
              return (
                  item.type_pagebreak === 'catatanp5' &&
                  item.id_ta === idTa &&
                  item.id_unit === idUnit &&
                  item.id_unitlevel === idUnitlevel
              );
          });
          return nilai ? nilai.status : null; // Pastikan 'status' tidak undefined
      } else {
          return null;
      }
    };
  
      const perPageOptions = [5, 10, 15, 25, 50, 100];
  
  
      const changePage = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("global/getPagebreakCatatan", {
          page: page,
          perPage: jumlah,
          search: pencarian,
          unit_id:data.unit_id,
  
        });
      };
  
      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
  
        await store.dispatch("global/getPagebreakCatatan", {
          page: 1,
          perPage: jumlah,
          search: pencarian
        });
      };
  
      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("global/getPagebreakCatatan", {
          page: 1,
          perPage: jumlah,
        
          unit_id:data.unit_id,
          search: pencarian
        });
        // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      
      const changeUnit = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("global/getPagebreakCatatan", {
          page: 1,
          perPage: jumlah,
          id_ta:data.id_ta,
          unit_id:data.unit_id,
          search: pencarian
        });
        // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      const changeTa = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("global/getPagebreakCatatan", {
          page: 1,
          perPage: jumlah,
          id_ta:data.id_ta,
          unit_id:data.unit_id,
          search: pencarian
        });
        // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      function confirmDelete(id) {
        Swal.fire({
          title: 'Konfirmasi',
          text: 'Apakah Anda yakin ingin menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('prestasisiswa/destroy', id)
              .then(() => {
                store.dispatch("global/getPagebreakCatatan");
                router.push({ name: "admin-prestasisiswa" });
                //alert
                Swal.fire({
                  title: 'BERHASIL!',
                  text: "Data Berhasil Dihapus!",
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 2000
                })
              })
          }
        });
      }
  
      async function handleSwitchChange(e) {
     
        const id_unit = e.currentTarget.getAttribute('id_unit');
        const id_unitlevel =e.currentTarget.getAttribute('id_unitlevel');
        const id_ta = e.currentTarget.getAttribute('id_ta');
        const isChecked = e.target.checked !== undefined ? e.target.checked : e.currentTarget.checked;
        let formData = new FormData();
        formData.append("type_pagebreak", "catatanp5");
    
        formData.append("id_unit", id_unit);
        formData.append("id_unitlevel", id_unitlevel);
        formData.append("id_ta", id_ta == null ? data.id_ta : id_ta);
        formData.append("status", isChecked==true ? 1: 0);
        formData.append("_method", "POST");
        // console.log("Form data : ",formData);
        try {
          const response = await store.dispatch("global/storePagebreak",formData);
          // console.log("Response server: ", response.data.success); // Logging response setelah dispatch berhasil
          Swal.fire({
              icon: response.data.success == true ? 'success':'error',
              title: response.data.message,
            });
  
            if (response.data.success == false) {
              e.target.checked = false; // Mengembalikan checkbox ke tidak tercentang
            }
            
  
        } catch (error) {
          console.error("Error:", error); // Logging error jika terjadi kesalahan
          validation.value = error;
        }
      }
      
  
  
      setNavPills();
      setTooltip(store.state.bootstrap);
  
      return {
        pagebreak,
        menus,
        units,
        tas,
        changePage,
        changePerPage,
        perPageOptions,
        data,
        searchData,
        confirmDelete,
        datapermissions,
        handleSwitchChange,
        getUser,
        changeUnit,
        changeTa,
        getStatus
      };
    },
  };
  </script>
  <style>
          .switch {
              position: relative;
              display: inline-block;
              width: 40px; /* Lebar lebih kecil */
              height: 20px; /* Tinggi lebih kecil */
          }
  
          /* Switch background */
          .switch input {
              opacity: 0;
              width: 0;
              height: 0;
          }
  
          .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              transition: 0.4s;
              border-radius: 20px; /* Sesuaikan border-radius agar sesuai dengan tinggi */
          }
  
          /* Circle inside the switch */
          .slider::before {
              position: absolute;
              content: "";
              height: 16px; /* Sesuaikan ukuran tombol bulat */
              width: 16px; /* Sesuaikan ukuran tombol bulat */
              left: 2px;
              bottom: 2px;
              background-color: white;
              transition: 0.4s;
              border-radius: 50%;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
  
          input:checked + .slider {
              background-color: rgba(94, 114, 228, 0.95); /* Background color when checked */
          }
  
          input:checked + .slider::before {
              transform: translateX(20px); /* Gerakan tombol bulat ke kanan */
          }
  
          /* Optional: Add a shadow effect */
          .switch .slider {
              box-shadow: 0 0 1px #ccc;
          }
  
          /* Add a hover effect */
          .switch input:hover + .slider {
              background-color: #aaa;
          }
  
          .form-switch .form-check-input:checked {
            height: 20px !important;
          }
  
          .form-switch .form-check-input {
            height: 20px !important;
          }
  
  
          /* Menargetkan elemen input checkbox */
  input[type="checkbox"] {
      /* Misalnya, membuat checkbox lebih besar */
      width: 20px;
      height: 20px;
      cursor: pointer;
  }
  
  /* Saat checkbox dicentang */
  input[type="checkbox"]:checked {
      background-color: #4a90e2;
      border: 2px solid #4a90e2;
  }
  
  /* Menargetkan checkbox ketika hover */
  input[type="checkbox"]:hover {
      border-color: #888;
  }
  
  /* Menargetkan checkbox saat aktif (ketika user klik) */
  input[type="checkbox"]:active {
      background-color: #4a90e2;
      border-color: #4a90e2;
  }
  
  </style>