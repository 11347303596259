<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit Master P5 </h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updatemasterp5">
               
                 
  
  
                    <!-- <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1"
                            >Tahun Ajaran <span class="text-danger">*</span></label
                          >
                          <select class="form-control" v-model="masterp5.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{ tahunajaran.desc}}</option>
                        </select>
                          <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                        </div>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Proyek</label>
                          <input
                            type="text"
                            placeholder="Proyek"
                            class="form-control"
                            v-model="masterp5.proyek"
                          />
                          <small class="text-danger" v-if="validation.proyek">{{
                            validation.proyek[0]
                          }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Tema</label>
                          <input
                            type="text"
                            placeholder="Tema"
                            class="form-control"
                            v-model="masterp5.tema"
                          />
                          <small class="text-danger" v-if="validation.tema">{{
                            validation.tema[0]
                          }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Nama</label>
                          <input
                            type="text"
                            placeholder="Nama "
                            class="form-control"
                            v-model="masterp5.nama"
                          />
                          <small class="text-danger" v-if="validation.nama">{{
                            validation.nama[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Deskripsi</label>
                          <textarea class="form-control"  v-model="masterp5.deskripsi">
                           
                          </textarea>
                          <small class="text-danger" v-if="validation.deskripsi">{{
                            validation.deskripsi[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
  
                  
  
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                    
                        &nbsp;
                        <router-link :to="{name:'admin-masterp5',params:{id:$route.query.idkelas}}" class="btn btn-danger btn-sm">Kembali</router-link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive, computed, onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "masterp5Index",
    components: {},
    data() {
      return {
        showmasterp5: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const masterp5 = reactive({
      id_unit:"",
        id_ta: "",
        tema:"",
        deskripsi:"",
        nama:"",
        proyek:"",
        id_unitlevel:""
      
    });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      const route = useRoute();

      
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updatemasterp5() {
        //define variable
     
  
        let formData = new FormData();
  
      
        // formData.append('id_ta', masterp5.id_ta)
        // formData.append('id_unit', masterp5.id_unit)
        formData.append('id_unitlevel', route.params.idunitlevel)
        formData.append('nama', masterp5.nama)
          formData.append('tema', masterp5.tema)
          formData.append('proyek', masterp5.proyek)
          formData.append('deskripsi', masterp5.deskripsi)
        
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("masterp5/update", {
            masterp5Id: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-masterp5",params:{kelasid:route.query.idkelas}});
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch('tahunajaran/getAll')
        await store.dispatch("units/getAll")
        await store.dispatch("masterp5/getDetail", route.params.id)
        await store.dispatch("unitlevel/getAlls");
      });
  
       //computed
       const tahunajarans = computed(() => {
        //panggil getter dengan nama "getParent" di module "masterp5" vuex
        return store.getters["tahunajaran/getAll"];
      });

      const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAlls"];
    });

    const changeUnit = async () => {
      await store.dispatch("unitlevel/getAlls",
        masterp5.id_unit
      );
    };
  
      const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["units/getAll"];
    });
  
  
      watch(
        () => store.state.masterp5.details,
        (newmasterp5) => {
          if (newmasterp5) {
            const {
              id_ta,
              id_unit,
              nama,
              proyek,
              deskripsi,
              id_unitlevel,
              tema
             
        
            } = newmasterp5;
            
            masterp5.id_ta = id_ta;
            masterp5.id_unit = id_unit;
            masterp5.id_unitlevel = id_unitlevel;
            masterp5.nama = nama;
            masterp5.deskripsi = deskripsi;
            masterp5.tema = tema;
            masterp5.proyek = proyek;
         
          
          }
        }
      );
  
      //computed
  
  
      //return a state and function
      return {
        masterp5, // <-- state user
        validation, // <-- state validation
        updatemasterp5,
        tahunajarans,
        units,
        unitlevels,
        changeUnit
       
      };
    },
  };
  </script>