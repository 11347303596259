import Api from '../../api/Api'
const global = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        provinsi:[],
        kota: [],
        kecamatan: [],
        kelurahan: [],
        bykelas: [],
        bulan:[],
        bulanaktif:{},
        bulanSemester:[],
        pagebreakcatatan:[],
        pagebreakmapel:[],
        pagebreak:[]
    },

    //mutations
    mutations: {
      
        SET_PROVINSI(state, provinsi) {
            state.provinsi = provinsi
        },
        SET_BULAN(state, bulan) {
            state.bulan = bulan
        },
        SET_BULAN_AKTIF(state, bulanaktif) {
            state.bulanaktif = bulanaktif
        },
        SET_KOTA(state, kota) {
            state.kota = kota
        },
        SET_KECAMATAN(state, kecamatan) {
            state.kecamatan = kecamatan
        },
        SET_KELURAHAN(state, kelurahan) {
            state.kelurahan = kelurahan
        },
        GET_BYKELAS(state, bykelas){
            state. bykelas = bykelas
        },
        SET_BULAN_SEMESTER(state, bulanSemester) {
            state.bulanSemester = bulanSemester
        },
        GET_PAGEBREAK_CATATAN(state, pagebreakcatatan){
            state. pagebreakcatatan = pagebreakcatatan
        },

        GET_PAGEBREAK_MAPEL(state, pagebreakmapel){
            state. pagebreakmapel = pagebreakmapel
        },

        GET_PAGEBREAK(state, pagebreak){
            state. pagebreak = pagebreak
        },
      
    },

    _actions: {
      
        storePagebreak({ commit }, formData) {
           

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/pagebreak', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },
        getPagebreak({ commit }) {
       
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pagebreak`)
                .then(response => {
                    // //console.log('inis',response.data.data)
                    commit('GET_PAGEBREAK', response.data.data)
                })
        },

        getPagebreakCatatan({ commit }, payload) {
            let unit = payload ? payload.unit_id : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
          let id_ta = payload ? payload.id_ta : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pagebreak-catatan?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unit=${unit}&id_ta=${id_ta}`)
                .then(response => {
                    // //console.log('inis',response.data.data)
                    commit('GET_PAGEBREAK_CATATAN', response.data.data)
                })
        },

        getPagebreakMapel({ commit }, payload) {
            let unit = payload ? payload.unit_id : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
             let id_ta = payload ? payload.id_ta : ''
               let id_unitlevel = payload ? payload.unitlevel : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pagebreak-mapel?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unit=${unit}&id_ta=${id_ta}&unitlevel=${id_unitlevel}`)
                .then(response => {
                    // //console.log('inis',response.data.data)
                    commit('GET_PAGEBREAK_MAPEL', response.data.data)
                })
        },

        
        getProvinsi({ commit }) {
       
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/provinsi`)
                .then(response => {
                    // //console.log('inis',response.data.data)
                    commit('SET_PROVINSI', response.data.data)
                })
        },

        getBulan({ commit }) {
       
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bulan`)
                .then(response => {
                    //console.log('inis',response.data.data)
                    commit('SET_BULAN', response.data.data)
                })
        },

        getBulanAktif({ commit }, payload) {
            console.log(payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bulanaktif?bulan=${payload}`)
                .then(response => {
                    
                    commit('SET_BULAN_AKTIF', response.data.data)
                })
        },

        getKetNilai({ commit }, payload) {

            
            //define variable token
            const token = localStorage.getItem('token')
          
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ketnilai?id=${payload}`)
                .then(response => {
                    // console.log('ini kategorieess', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_BYKELAS', response.data.data)

                })
        },

        getCity({ commit }, payload) {

            //define variable token
            let id_province = payload ? payload : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kota?id_province=${id_province}`)
                .then(response => {
                    // //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_KOTA', response.data.data)

                })
        },

        getDistrict({ commit }, payload) {

            //define variable token
            let id_city = payload ? payload : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kecamatan?id_city=${id_city}`)
                .then(response => {
                    // //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_KECAMATAN', response.data.data)

                })
        },

        getSubDistrict({ commit }, payload) {

            //define variable token
            let id_district = payload ? payload : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelurahan?id_district=${id_district}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_KELURAHAN', response.data.data)

                })
        },

        getBulanSemester({ commit }) {
       
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/bulan-semester`)
                .then(response => {
                    //console.log('inis',response.data.data)
                    commit('SET_BULAN_SEMESTER', response.data.data)
                })
        },

     

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       
        getPagebreak(state) {
            return state.pagebreak
        },

        getPagebreakCatatan(state) {
            return state.pagebreakcatatan
        },

        getPagebreakMapel(state) {
            return state.pagebreakmapel
        },

        getProvinsi(state) {
            return state.provinsi
        },

        getBulan(state) {
            return state.bulan
        },

        getBulanAktif(state) {
            return state.bulanaktif
        },

        getCity(state){
            return state.kota
        },

        getDistrict(state){
            return state.kecamatan
        },

        getSubDistrict(state){
            return state.kelurahan
        },

        getKetNilai(state){
            return state.bykelas
        },

        getBulanSemester(state) {
            return state.bulanSemester
        },
    }

}

export default global