<template>
  <div class="container-fluid p-5">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h3>Input Nilai Ceklis</h3>
              <div class="d-flex">
                <p>Nama Pengajar : {{ guru.fullname }}</p>
                <button @click="goBack" class="btn btn-sm btn-primary">
                  Kembali
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <div class="col-md-12" v-if="kelas.length != 0">
                <div class="form-group">
                  <label for="exampleFormControlInput1"
                    >Pilih kelas yang diajar:<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <select
                    class="form-control"
                    v-model="data.bebanmengajarid"
                    @change="changeSiswa"
                  >
                    <option value="">-- select kelas --</option>
                    <option
                      v-for="k in kelas"
                      :key="k.id"
                      :value="k.id"
                      :id-ta="k.idta"
                      :id-mapel="k.idmapel"
                      :id-kelas="k.idkelasaktif"
                      :id-unit="k.id_unit"
                      :id-unitlevel="k.id_unitlevel"
                    >
                      {{ k.classname }} - {{ k.nama_mapel }} 
                    </option>
                  </select>
                </div>
              </div>

              <div style="height: 20px !important" v-else>
                <ContentLoader viewBox="0 0 250 110">
                  <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                
                </ContentLoader>
              </div>

              <div class="col-md-12" v-if="siswa.length != 0">
                <div class="form-group">
                  <label for="exampleFormControlInput1"
                    >Pilih Siswa:<span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    v-model="data.siswaid"
                    @change="changeMurid"
                  >
                    <option value="">-- select siswa --</option>
                    <option
                      v-for="s in siswa"
                      :key="s.id_siswa"
                      :value="s.id_siswa"
                      :id-mapelsiswa="s.idmapelsiswa"
                    >
                      {{ s.nama }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-else>
                <ContentLoader viewBox="0 0 250 110">
                  <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                
                </ContentLoader>
              </div>
            </div>
            <div v-if="isLoading">
              <ContentLoader />
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <table>
                  <template v-for="(e, index) in elements" :key="e.id">
                   
                    <thead>
                      <tr v-if="index == 0">
                        <th>Capaian Pembelajaran</th>
                        <th>Indikator Capaian Pembelajaran</th>
                        <th>Nilai</th>
                      </tr>
                      <tr v-if="e.nama_element">
                        <th colspan="3">{{ e.nama_element }}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-if="e.nama_element">
                        <tr v-for="c in e.cp" :key="c.id" >
                          <td :style="{ width: '50%' }">
                            {{ c.nama_cp }}
                          </td>
                          <td style="vertical-align: middle">
                            <ul class="m0" >
                              <li
                                v-for="i in c.indikator"
                                :key="i.id"
                                class="my-2"
                                style="list-style: none"
                              >
                                {{ i.nama_indikator }}
                              </li>
                            </ul>
                          </td>
                          <td style="vertical-align: top">
                            <li
                              v-for="i in c.indikator"
                              :key="i.id"
                              class="my-3"
                              style="list-style: none; "
                            >
                              <select
                                class="form-control"
                                @change="
                                  handleSelectChange(
                                    data.siswaid,
                                    e.id,
                                    c.id,
                                    i.id,
                                    $event
                                  )
                                "
                              >
                                <option
                                  value=""
                                  :selected="
                                    getNilai(data.siswaid, i.id) === ''
                                  "
                                ></option>
                                <option
                                  value="SB"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'SB'
                                  "
                                >
                                  SB
                                </option>
                                <option
                                  value="BSH"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'BSH'
                                  "
                                >
                                  BSH
                                </option>
                                <option
                                  value="MB"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'MB'
                                  "
                                >
                                  MB
                                </option>
                                <option
                                  value="BB"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'BB'
                                  "
                                >
                                  BB
                                </option>
                              </select>
                            </li>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          
                          <td :style="{ width: '50%' }">
                            {{ e.nama_cp }}
                          </td>
                          <td style="vertical-align: middle">
                            <ul class="m0">
                              <li
                                v-for="i in e.indikator"
                                :key="i.id"
                                class="my-4"
                                style="list-style: none"
                              >
                                {{ i.nama_indikator }}
                              </li>
                            </ul>
                          </td>
                          <td style="vertical-align: middle" width="75">
                            <li
                              v-for="i in e.indikator"
                              :key="i.id"
                              class="my-5"
                              style="list-style: none"
                            >
                              <select
                                class="form-control"
                                @change="
                                  handleSelectChange(
                                    data.siswaid,
                                    e.id,
                                    e.id,
                                    i.id,
                                    $event
                                  )
                                "
                              >
                                <option
                                  value=""
                                  :selected="
                                    getNilai(data.siswaid, i.id) === ''
                                  "
                                ></option>
                                <option
                                  value="SB"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'SB'
                                  "
                                >
                                  SB
                                </option>
                                <option
                                  value="BSH"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'BSH'
                                  "
                                >
                                  BSH
                                </option>
                                <option
                                  value="MB"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'MB'
                                  "
                                >
                                  MB
                                </option>
                                <option
                                  value="BB"
                                  :selected="
                                    getNilai(data.siswaid, i.id) === 'BB'
                                  "
                                >
                                  BB
                                </option>
                              </select>
                            </li>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { computed, ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";

// const body = document.getElementsByTagName("body")[0];
export default {
  name: "inputnilai",
  components: {
    ContentLoader,
  },
  // created() {
  //     this.$store.state.hideConfigButton = true;
  //     this.toggleDefaultLayout();
  //     body.classList.remove("bg-gray-100");
  // },
  // beforeUnmount() {
  //     this.$store.state.hideConfigButton = false;
  //     this.toggleDefaultLayout();
  //     body.classList.add("bg-gray-100");
  // },

  setup() {
    const isLoading = ref(false);
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    const data = reactive({
      kelasid: "",
      bebanmengajarid: "",
      idmapel: "",
      idunitlevel: "",
      idunit: "",
      idta: "",
      idsiswa: "",
      pages: "penilaian-view",
    });

    const guru = reactive({
      fullname: "",
      nickname: "",
      gender: "",
      birthplace: "",
      birthdate: "",
      marital_status: "",
      wni: "",
      religion: "",
      edulevel: "",
      address: "",
      id_province: "",
      id_city: "",
      id_district: "",
      id_subdistrict: "",
      cellphone: "",
      email: "",
      lang: "",
      nuptk: "",
    });

    const validation = ref([]);
    const store = useStore();
    const route = useRoute();

    const kelas = computed(() => {
      return store.getters["bebanmengajar/getKelasGuruData"];
    });

    const siswa = computed(() => {
      return store.getters["bebanmengajar/getSiswa"];
    });

    const elements = computed(() => {
      //panggil getter dengan nama "getParent" di module "kelas" vuex
      return store.getters["element/getData"];
    });

    const changeSiswa = async (event) => {
      const selectedIdMapel =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-mapel"
        );

      data.idmapel =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-mapel"
        );
      data.idta =
        event.target.options[event.target.selectedIndex].getAttribute("id-ta");
      data.idunit =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-unit"
        );
      data.idunitlevel =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-unitlevel"
        );
      data.kelasid =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-kelas"
        );

      await store.dispatch("bebanmengajar/getSiswa", {
        id: data.bebanmengajarid,
        idmapel: selectedIdMapel,
        guruid: route.params.id,
      });

      // await store.dispatch("element/getData");
    };

    const changeMurid = async () => {
      isLoading.value = true;
      // alert(data.idta)
      // data.idmapel = event.target.options[event.target.selectedIndex].getAttribute('id-mapelsiswa');
      await store.dispatch("element/getData", {
        id_mapel: data.idmapel,
        id_unit: data.idunit,
        id_ta: data.idta,
        id_unitlevel: data.idunitlevel,
      });

      await store.dispatch("nilaiceklis/getData", {
        id_kelas: data.kelasid,
        id_siswa: data.siswaid,
        id_mapel: data.idmapel,
      });

      setTimeout(() => {
        isLoading.value = false;
      }, 3000);
    };

    const nilai = computed(() => {
      return store.getters["nilaiceklis/getData"];
    });

    async function handleSelectChange(
      siswaid,
      elementId,
      cpId,
      indikatorId,
      event
    ) {
      try {
        // Aksi yang ingin Anda lakukan ketika nilai elemen select berubah
        const selectedValue = event.target.value;

        // console.log("Selected value changed to:", selectedValue);

        let formData = new FormData();
        formData.append("nilai", selectedValue);
        formData.append("id_siswa", siswaid);
        formData.append("id_element", elementId);
        formData.append("id_cp", cpId);
        formData.append("id_indikator", indikatorId);
        formData.append("id_kelas", data.kelasid);
        formData.append("id_mapel", data.idmapel);
        formData.append("id_unitlevel", data.idunitlevel);

        console.log("ini form", formData);

        await store.dispatch("nilaiceklis/store", formData);

        // Tambahan logika atau pemanggilan fungsi sesuai kebutuhan
      } catch (error) {
        validation.value = error;

        Swal.fire({
          title: "GAGAL!",
          text: validation.value.error,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });

        console.error("An error occurred:", error);
        store.dispatch("auth/logout").then(() => {
        //jika berhasil, akan di arahkan ke route login
          router.push({
            name: "login",
          });
        });
      }
    }

    onMounted(async () => {
      await store.dispatch("guru/getDetail", route.params.id);
      await store.dispatch("bebanmengajar/getKelasGuruData", route.params.id);
      // await store.dispatch("bebanmengajar/getSiswa")
      await store.dispatch("nilaipernyataan/getData");
      await store.dispatch("nilaiceklis/getData", {
        id_kelas: data.bebanmengajarid,
        id_siswa: data.siswaid,
        id_mapel: data.idmapel,
      });

      await store.dispatch("kategoripenilaian/getKategori");

      await store.dispatch("element/getData", {
        id_mapel: data.idmapel,
        id_unit: data.idunit,
        id_ta: data.idta,
        id_unitlevel: data.idunitlevel,
      });
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const getNilai = (siswaid, indikatorid) => {
      const nilais = nilai.value.find((item) => {
        return item.id_siswa === siswaid && item.id_indikator === indikatorid;
      });

      console.log("nilais:", nilais);

      if (nilais) {
        return nilais.nilai;
      } else {
        return "";
      }
    };

    watch(
      () => store.state.guru.details,
      (newguru) => {
        //console.log('newguru',newguru);
        if (newguru) {
          const {
            fullname,
            nickname,
            gender,
            birthplace,
            birthdate,
            marital_status,
            wni,
            religion,
            edulevel,
            address,
            id_province,
            id_city,
            id_district,
            id_subdistrict,
            cellphone,
            email,
            lang,
            nuptk,
          } = newguru;
          guru.fullname = fullname;
          guru.nickname = nickname;
          guru.gender = gender;
          guru.birthplace = birthplace;
          guru.birthdate = birthdate;
          guru.marital_status = marital_status;
          guru.wni = wni;
          guru.religion = religion;
          guru.edulevel = edulevel;
          guru.address = address;
          guru.id_province = id_province;
          guru.id_city = id_city;
          guru.id_district = id_district;
          guru.id_subdistrict = id_subdistrict;
          guru.cellphone = cellphone;
          guru.email = email;
          guru.lang = lang;
          guru.nuptk = nuptk;
        }
      }
    );

    return {
      validation,
      isLoading,
      guru,
      kelas,
      data,
      changeSiswa,
      siswa,
      getNilai,
      elements,
      handleSelectChange,
      changeMurid,
      nilai,
      goBack,
    };
  },

  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
  },
};
</script>

<style>
.table-container {
  overflow: auto;
  max-height: 400px;
}

table.table-bordered th {
  position: sticky;
  top: 0;
  background-color: white;
}

.form-nilai {
  max-width: 100px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

td {
  color: black;
}
</style>


<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>