<template>
  <div class="container-fluid p-5">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h3>Input Nilai P5</h3>
              <div class="d-flex gap-4 items-center">
                <p>Wali Kelas : {{ kelas.wali_kelas }}</p>
                <router-link
                  :to="{ name: 'admin-p5' }"
                  class="btn btn-sm btn-danger"
                  >Kembali</router-link
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleFormControlInput1"
                    >Pilih Siswa:<span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    v-model="data.siswaid"
                    @change="changeMurid"
                  >
                    <option value="">-- select siswa --</option>
                    <option
                      v-for="s in siswa"
                      :key="s.id_siswa"
                      :value="s.id_siswa"
                    >
                      {{ s.nama }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div v-if="isLoading" class="col-12">
                <ContentLoader />
              </div>
              <div
                v-else
                class="col-12"
                v-for="n in master_p5.data"
                :key="n.id"
              >
                <table>
                  <tr>
                    <!-- <td>Proyek</td> -->
                    <td>{{ n.proyek }}</td>
                  </tr>
                  <!-- <tr>
                    <td>Tema</td>
                    <td>{{ n.tema }}</td>
                  </tr>
                  <tr>
                    <td>Nama</td>
                    <td>{{ n.nama }}</td>
                  </tr>
                  <tr>
                    <td>Deskripsi</td>
                    <td>{{ n.deskripsi }}</td>
                  </tr> -->
                </table>

                <div class="row mt-5" v-if="n.dimensi">
                  <table>
                    <template v-for="(d, index) in n.dimensi" :key="d.id">
                      <thead>
                        <tr v-if="index == 0">
                          <th>Dimensi</th>
                          <th>Element</th>
                          <th>Target</th>
                          <th>Nilai</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ d.nama_dimensi }}
                          </td>
                          <td>
                            <ul>
                              <li v-for="i in d.element" :key="i.id">
                                {{ i.nama_element }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <template v-for="i in d.element" :key="i.id">
                                <li v-for="t in i.target" :key="t.id">
                                  {{ t.nama_target }}
                                </li>
                              </template>
                            </ul>
                          </td>
                          <td
                          width="150"
                            style="vertical-align: middle; text-align: center"
                          >
                            <ul v-if="hideNilai">
                              <template v-for="i in d.element" :key="i.id">
                                <li
                                  v-for="t in i.target"
                                  :key="t.id"
                                  style="list-style: none; text-align: center"
                                >
                                  <select
                                    name=""
                                    class="form-control mx-auto w-full"
                                    id=""
                                    @change="
                                      handleSelectChange(
                                        data.siswaid,
                                        t.id,
                                        $event
                                      )
                                    "
                                  >
                                    <option
                                      value=""
                                      :selected="
                                        getNilai(data.siswaid, i.id, t.id) ===
                                        ''
                                      "
                                    ></option>
                                    <option
                                      value="SB"
                                      :selected="
                                        getNilai(data.siswaid, i.id, t.id) ===
                                        'SB'
                                      "
                                    >
                                      SB
                                    </option>
                                    <option
                                      value="BSH"
                                      :selected="
                                        getNilai(data.siswaid, i.id, t.id) ===
                                        'BSH'
                                      "
                                    >
                                      BSH
                                    </option>
                                    <option
                                      value="MB"
                                      :selected="
                                        getNilai(data.siswaid, i.id, t.id) ===
                                        'MB'
                                      "
                                    >
                                      MB
                                    </option>
                                    <option
                                      value="BB"
                                      :selected="
                                        getNilai(data.siswaid, i.id, t.id) ===
                                        'BB'
                                      "
                                    >
                                      BB
                                    </option>
                                  </select>
                                </li>
                              </template>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                </div>
                <div v-else>
                  <ContentLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { computed, ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ContentLoader } from "vue-content-loader";
import Swal from "sweetalert2";

// const body = document.getElementsByTagName("body")[0];
export default {
  name: "inputnilai",
  // created() {
  //     this.$store.state.hideConfigButton = true;
  //     this.toggleDefaultLayout();
  //     body.classList.remove("bg-gray-100");
  // },
  // beforeUnmount() {
  //     this.$store.state.hideConfigButton = false;
  //     this.toggleDefaultLayout();
  //     body.classList.add("bg-gray-100");
  // },

  components: {
    ContentLoader,
  },

  setup() {
    const hideNilai = ref(false);
    const isLoading = ref(false);
    const masterp5 = reactive({
      proyek: "",
      tema: "",
      nama: "",
      deskripsi: "",
      dimensi: [],
    });

    const data = reactive({
      kelasid: "",
      bebanmengajarid: "",
      idmapel: "",
      idunitlevel: "",
      idunit: "",
      idta: "",
      idsiswa: "",
      siswaid: "",
    });

    const kelas = reactive({
      classname: "",
      id_ta: "",
      wali_kelas: "",
      guru_pendamping: "",
      id_unitlevel: "",
      id_unit: "",
    });

    const guru = reactive({
      fullname: "",
      nickname: "",
      gender: "",
      birthplace: "",
      birthdate: "",
      marital_status: "",
      wni: "",
      religion: "",
      edulevel: "",
      address: "",
      id_province: "",
      id_city: "",
      id_district: "",
      id_subdistrict: "",
      cellphone: "",
      email: "",
      lang: "",
      nuptk: "",
    });

    const master_p5 = computed(() => {
      return store.getters["masterp5/getData"];
    });

    const validation = ref([]);
    const store = useStore();
    const route = useRoute();

    // const kelass = computed(() => {
    //   return store.getters["bebanmengajar/getKelasGuruData"];
    // });

    const siswa = computed(() => {
      return store.getters["bebanmengajar/getSiswaSikapwalas"];
    });

    const changeSiswa = async (event) => {
      const selectedIdMapel =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-mapel"
        );

      data.idmapel =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-mapel"
        );
      data.idta =
        event.target.options[event.target.selectedIndex].getAttribute("id-ta");
      data.idunit =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-unit"
        );
      data.idunitlevel =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-unitlevel"
        );
      data.kelasid =
        event.target.options[event.target.selectedIndex].getAttribute(
          "id-kelas"
        );

      await store.dispatch("bebanmengajar/getSiswa", {
        id: data.bebanmengajarid,
        idmapel: selectedIdMapel,
        guruid: route.params.id,
      });
    };

    const changeMurid = async () => {
      isLoading.value = true;
      await store.dispatch("masterp5/getData", { id: route.params.id });
      await store.dispatch("nilaip5/getData", {
        id: route.params.id,
        id_siswa: data.siswaid,
      });
      hideNilai.value = true;
      setTimeout(() => {
          isLoading.value = false;
        }, 5000);
    };

    const nilai = computed(() => {
      return store.getters["nilaip5/getData"];
    });

    async function handleSelectChange(siswaid, targetId, event) {
      try {
       
        
        const selectedValue = event.target.value;
        let formData = new FormData();
        formData.append("nilai", selectedValue);
        formData.append("id_siswa", data.siswaid);
        formData.append("id_target_p5", targetId);
        formData.append("id", route.params.id);
        // console.log('ini form', formData)

        await store.dispatch("nilaip5/store", formData);
       
        // Tambahan logika atau pemanggilan fungsi sesuai kebutuhan
      } catch (error) {
        validation.value = error;

        Swal.fire({
          title: "GAGAL!",
          text: validation.value.error,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });

        console.error("An error occurred:", error);
      }
    }

    onMounted(async () => {
      isLoading.value = true;
      await store.dispatch("guru/getDetail", route.params.id);
      await store.dispatch("bebanmengajar/getKelasGuruData", route.params.id);
      await store.dispatch("bebanmengajar/getSiswaSikapwalas", route.params.id);
      await store.dispatch("nilaipernyataan/getData");
      await store.dispatch("nilaip5/getData", {
        id: route.params.id,
        id_siswa: data.siswaid,
      });
      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch("kategoripenilaian/getKategori");
      await store.dispatch("masterp5/getData", { id: route.params.id });
      await store.dispatch("element/getData", {
        id_mapel: data.idmapel,
        id_unit: data.idunit,
        id_ta: data.idta,
        id_unitlevel: data.idunitlevel,
      });
      setTimeout(() => {
        isLoading.value = false;
      }, 10000);
    });

    // const master = computed(() => {
    //   //panggil getter dengan nama "getParent" di module "kelas" vuex
    //   return store.getters["masterp5/getData"];
    // });

    const getNilai = (siswaid, idkelas, idtarget) => {
      siswaid = data.siswaid;
      idkelas = route.params.id;

      const nilais = nilai.value.find((item) => {
        return (
          item.id_siswa === siswaid && item.id_target_p5 === idtarget
          // &&
          // item.id_kelas === idkelas
        );
      });

      // console.log('nilais:', nilais);

      if (nilais) {
        console.log("x", nilais.nilai);
        return nilais.nilai;
      } else {
        return "";
      }
    };

    watch(
      () => store.state.masterp5.datas,
      (newmaster) => {
        // console.log('newmaster', newmaster.data[0]);
        if (newmaster) {
          const { data } = newmaster;
          masterp5.proyek = data[0] ? data[0].proyek : "";
          masterp5.nama = data[0] ? data[0].nama : "";
          masterp5.tema = data[0] ? data[0].tema : "";
          masterp5.deskripsi = data[0] ? data[0].deskripsi : "";
          masterp5.dimensi = data[0] ? data[0].dimensi : "";
        }
      }
    );

    watch(
      () => store.state.guru.details,
      (newguru) => {
        //console.log('newguru',newguru);
        if (newguru) {
          const {
            fullname,
            nickname,
            gender,
            birthplace,
            birthdate,
            marital_status,
            wni,
            religion,
            edulevel,
            address,
            id_province,
            id_city,
            id_district,
            id_subdistrict,
            cellphone,
            email,
            lang,
            nuptk,
          } = newguru;
          guru.fullname = fullname;
          guru.nickname = nickname;
          guru.gender = gender;
          guru.birthplace = birthplace;
          guru.birthdate = birthdate;
          guru.marital_status = marital_status;
          guru.wni = wni;
          guru.religion = religion;
          guru.edulevel = edulevel;
          guru.address = address;
          guru.id_province = id_province;
          guru.id_city = id_city;
          guru.id_district = id_district;
          guru.id_subdistrict = id_subdistrict;
          guru.cellphone = cellphone;
          guru.email = email;
          guru.lang = lang;
          guru.nuptk = nuptk;
        }
      }
    );

    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const {
            wali_kelas,
            master_kelas,
            id_ta,

            guru_pendamping,
          } = newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.id_unit = master_kelas.unit_level.id_unit;
          kelas.wali_kelas = wali_kelas ? wali_kelas.fullname : "";
          kelas.guru_pendamping = guru_pendamping
            ? guru_pendamping.fullname
            : "";
          kelas.id_unitlevel = master_kelas.id_unitlevel;
        }
      }
    );

    return {
      validation,
      guru,
      kelas,
      data,
      changeSiswa,
      siswa,
      getNilai,
      handleSelectChange,
      changeMurid,
      nilai,
      hideNilai,
      // master,
      masterp5,
      master_p5,
      isLoading
      // kelass
    };
  },

  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
  },
};
</script>

<style>
.table-container {
  overflow: auto;
  max-height: 400px;
}

table.table-bordered th {
  position: sticky;
  top: 0;
  background-color: white;
}

.form-nilai {
  max-width: 100px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

td {
  color: black;
}
</style>


<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>