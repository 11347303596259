/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import mixins from './mixins';


import Select2 from 'vue3-select2-component';
import  CKEditorComponentData  from "@ckeditor/ckeditor5-vue/dist/ckeditor";
import Select2MultipleControl from 'v-select2-multiple-component';

const appInstance = createApp(App);
//  appInstance.config.globalProperties.$baseURL = 'https://api-v2.ypii.net';
// appInstance.config.globalProperties.$baseURL = 'http://localhost:8000';
appInstance.config.globalProperties.$baseURL = 'https://api-v2.annisaa-izada.sch.id';
appInstance.use(CKEditorComponentData)
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(Select2);
appInstance.use(Select2MultipleControl);

appInstance.use(ArgonDashboard);
appInstance.use(mixins);
appInstance.mount("#app");
