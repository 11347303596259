<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Indikator Sikap</h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updatesubkategorisikap">
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="subkategorisikap.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Aspek <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="subkategorisikap.id_kategorisikap">
                          <option value="">-- pilih aspek --</option>
                          <option v-for="k in kategoris" :key="k.id" :value="k.id" :type="k.type_kategori">{{ k.level }}
                            -
                            {{ k.nama_kategori }} </option>
                        </select>
                        <small class="text-danger" v-if="validation.kategorisikap_id">{{ validation.kategorisikap_id[0]
                          }}</small>
                      </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Indikator </label>
                        <input type="text" placeholder="name" class="form-control"
                          v-model="subkategorisikap.nama_subkategorisikap" />
                        <small class="text-danger" v-if="validation.nama_subkategorisikap">{{
                  validation.nama_subkategorisikap[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlSelect2">Keterangan Nilai</label>
                        <select multiple class="form-control" id="exampleFormControlSelect2"
                          v-model="subkategorisikap.keterangannilai_id">
                          <option v-for="u in keterangannilai" :key="u.id" :value="u.id">{{ u.kode }}</option>
                        </select>
                        <small class="text-danger" v-if="validationketerangannilai_id">{{ validation.keterangannilai_id[0] }}</small>
                      </div>
                    </div>
                  </div> -->

                  <div class="row"
                    v-if="subkategorisikap.id_kategorisikap && kategoris.find(k => k.id === subkategorisikap.id_kategorisikap)?.type_kategori === 'mapel'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Jumlah Generate </label>
                        <input type="number" placeholder="name" class="form-control"
                          v-model="subkategorisikap.jumlah_generate" />
                        <small class="text-danger" v-if="validation.jumlah_generate">{{ validation.jumlah_generate[0]
                          }}</small>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                        Sub Kategori Parent <small class="text-danger">Kosongkan jika tidak ada parent</small></label
                        >
                        <select class="form-control" v-model="subkategorisikap.parent_id">
                        <option value="">-- select sub kategori sikap --</option>
                        <option v-for="u in parent" :key="u.id" :value="u.id">{{ u.nama_subkategori}}</option>
                      </select>
                        <small class="text-danger" v-if="validation.parent_id">{{ validation.parent_id[0] }}</small>
                      </div>
                    </div>
                  </div> -->

                  <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <div
                      v-for="(levels, unitId) in groupedUnitLevels"
                      :key="unitId"
                    >
                      <h6 v-if="unitId == 2">SD</h6>
                      <h6 v-else-if="unitId == 3">SMP</h6>
                      <h6 v-else-if="unitId == 4">SMA</h6>
                     <div class="d-flex">
                      <div
                        v-for="unitlevel in levels"
                        :key="unitlevel.id"
                        class="form-check"
                      >
                        <input
                          class="form-check-input ms-2"
                          type="checkbox"
                          v-model="data.idunitlevel"
                          :value="unitlevel.id"
                          :id="`check-${unitlevel.id}`"
                        />
                        <label class="custom-control-label">{{
                          unitlevel.name
                        }}</label>
                      </div>
                     </div>
                    </div>
                  </div>
                </div>
              </div>



                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-subkategorisikap' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "subkategorisikapIndex",
  components: {},
  data() {
    return {
      showsubkategorisikap: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const subkategorisikap = reactive({
      nama_subkategorisikap: '',
      id_ta: '',
      id_kategorisikap: '',
      jumlah_generate: '',
      // keterangannilai_id:[],
      parent_id: ''

    });

    const data = reactive({
      // currentPage: 1,
      idunitlevel: [],
      pages: "subkategorisikap-edit"

    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    onMounted(async () => {
      // await store.dispatch('subkategorisikap/getAll');
      await store.dispatch('kategorisikap/getAll');
      await store.dispatch('tahunajaran/getAll');
      await store.dispatch('keterangannilai/getAll');
      await store.dispatch("subkategorisikap/getDetail", route.params.id);
      await store.dispatch('subkategorisikap/getAll');
      await store.dispatch("unitlevel/getAllWithoutTk");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //computed
    const kategoris = computed(() => {
      return store.getters["kategorisikap/getAll"];
    });

    const keterangannilai = computed(() => {
      return store.getters["keterangannilai/getAll"];
    });

    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const parent = computed(() => {
      return store.getters["subkategorisikap/getAll"];
    });

    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAllWithoutTk"];
    });

 
    const groupedUnitLevels = computed(() => {
      const grouped = {};
      unitlevels.value.forEach((unitlevel) => {
        const unitKey = unitlevel.id_unit; // Group by id_unit
        if (!grouped[unitKey]) {
          grouped[unitKey] = [];
        }
        grouped[unitKey].push(unitlevel);
      });
      return grouped;
    });




    //function register, fungsi ini di jalankan ketika form di submit
    function updatesubkategorisikap() {
      //define variable


      let formData = new FormData();
      formData.append('id_ta', this.subkategorisikap.id_ta)
      formData.append('nama_subkategori', this.subkategorisikap.nama_subkategorisikap)
      formData.append('kategorisikap_id', this.subkategorisikap.id_kategorisikap)
      formData.append('generate', this.subkategorisikap.jumlah_generate)
      // formData.append("keterangannilai_id[]", this.subkategorisikap.keterangannilai_id);
      formData.append('parent_id', this.subkategorisikap.parent_id)
      formData.append("id_unitlevel[]", data.idunitlevel);
      // Array.from(data.idunitlevel).forEach(id_unitlevel => {
      //   formData.append("id_unitlevel[]", id_unitlevel);
      // })
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("subkategorisikap/update", {
          subkategorisikapId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-subkategorisikap" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }




    watch(
      () => store.state.subkategorisikap.details,
      (newsubkategorisikap) => {
        if (newsubkategorisikap) {
          const {
            id_ta,
            nama_subkategori,
            kategorisikap_id,
            // keterangannilai_id,
            generate,
            parent_id,
            id_unitlevel
          } = newsubkategorisikap;
          subkategorisikap.id_ta = id_ta;
          subkategorisikap.nama_subkategorisikap = nama_subkategori;
          // subkategorisikap.keterangannilai_id = JSON.parse(keterangannilai_id);
          subkategorisikap.id_kategorisikap = kategorisikap_id;
          subkategorisikap.jumlah_generate = generate ?? 1;
          subkategorisikap.parent_id = parent_id;
          data.idunitlevel = JSON.parse(id_unitlevel);


        }
      }
    );

    //computed


    //return a state and function
    return {
      unitlevels,
      subkategorisikap, // <-- state user
      validation, // <-- state validation
      updatesubkategorisikap,
      kategoris,
      tahunajarans,
      keterangannilai,
      parent,
      data,
      groupedUnitLevels 
    };
  },
};
</script>