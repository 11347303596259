<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Generate Subkategori penilaian</h5>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="generate">
                   
                 
                    <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran Asal<span class="text-danger">*</span></label>
                        <select class="form-control" v-model="kelas.old_id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.old_id_ta">{{ validation.old_id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran Tujuan<span class="text-danger">*</span></label>
                        <select class="form-control" v-model="kelas.new_id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.new_id_ta">{{ validation.new_id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>
  
  
                  
  
                    <div class="row">
                      <div class="col-12">
                        <button  :disabled="isLoading" type="submit" class="btn btn-success btn-sm">
                          <span v-if="isLoading">
                              <i class="fa fa-spinner fa-spin"></i> Loading...
                            </span>
                            <span v-else>
                              Generate
                            </span>
                        </button>
                        &nbsp;
  
                        <router-link :to="{ name: 'admin-subkategoripenilaian' }" class="btn btn-danger btn-sm">
                          Kembali 
                        </router-link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import Swal from 'sweetalert2';
  
  export default {
    name: "kelasIndex",
    components: {},
    data() {
      return {
        showkelas: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    
    setup() {
      //user state
      const isLoading = ref(false);
      const kelas = reactive({
      
        old_id_ta: '',
        new_id_ta: '',
     
      });
  
      const data = reactive({
  
        pages: 'managementkelas-view'
  
      });
  
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      //function register, fungsi ini di jalankan ketika form di submit
      function generate() {
  
        //define variable
        isLoading.value = true;
  
        let formData = new FormData();
  
       
        formData.append('old_id_ta', this.kelas.old_id_ta)
        formData.append('new_id_ta', this.kelas.new_id_ta)
    
        formData.append("_method", "POST");
        //panggil actions "register" dari module "auth"
        store
          .dispatch("subkategoripenilaian/generateSemester2",
            formData,
          )
          .then((response) => {
            isLoading.value = false;
            //redirect ke dashboard
            if(response.data.success == true){
              router.push({ name: "admin-subkategoripenilaian"  });
            Swal.fire({
              icon: 'success',
              title: 'Tambah data berhasil',
            });
            }else{
              router.push({ name: "admin-subkategoripenilaian" });
            Swal.fire({
              icon: 'false',
              title: response.data.message,
            });
            }
            
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            // //console.log(error);
  
            validation.value = error;
          });
      }
  
  
      onMounted(async () => {
        await store.dispatch("units/getAll");
        await store.dispatch('tahunajaran/getAll');
        await store.dispatch('unitlevel/getAlls');
        await store.dispatch('guru/getAll');
        const permissions = localStorage.getItem('permission');
  
        if (!permissions.includes(data.pages)) {
          router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
        }
      });
  
      //computed
      const tahunajarans = computed(() => {
        //panggil getter dengan nama "getParent" di module "kelas" vuex
        return store.getters["tahunajaran/getAll"];
      });
  
      const unitlevels = computed(() => {
        //panggil getter dengan nama "getAllPermission" di module "permission" vuex
        return store.getters["unitlevel/getAlls"];
      });
  
      const units = computed(() => {
        return store.getters["units/getAll"];
      });
  
      const gurus = computed(() => {
        //panggil getter dengan nama "getAllPermission" di module "permission" vuex
        return store.getters["guru/getAll"];
      });
  
      //return a state and function
      return {
        kelas, 
        validation, 
        generate,
        units,
        tahunajarans,
        unitlevels,
        gurus,
        data,
        isLoading,
      };
    },
  };
  </script>