<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Prestasi Siswa</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form ref="myForm" @submit.prevent="simpan">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran<small>*</small></label>
                        <select class="form-control" v-model="menu.id_ta">
                          <option :value="tahunajaran.id" selected>{{ tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Pilih Unit<small>*</small></label>
                        <select class="form-control" v-model="menu.unit_id" @change="changeUnit">
                          <option value="">-- Pilih Unit --</option>
                          <option v-for="unit in parentmenu" :key="unit.id" :value="unit.id">
                          {{ unit.name }}
                        </option>
                        </select>
                        <small class="text-danger" v-if="validation.unit_id">{{ validation.unit_id[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Siswa<small>*</small></label>
                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="searchTerm" 
                            @input="filterEkskul" 
                            :placeholder="detil.fullname"
                            :disabled="menu.siswa == false "
                          />

                          <ul class="list-group" v-if="filteredSiswa.length">
                            <li 
                              class="list-group-item" 
                              v-for="eks in filteredSiswa" 
                              :key="eks.id_student" 
                              @click="selectEkskul(eks)">
                              {{ eks.fullname }} {{ eks.classname }}
                            </li>
                          </ul>

                        <small class="text-danger" v-if="validation.student_id">{{ validation.student_id[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12">
                      {{ unituser }}
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kategori<small>*</small></label>
                        <select class="form-control" v-model="menu.kategori">
                          <option value="">-- Pilih Kategori --</option>
                          <option value="Akademik">Akademik</option>
                          <option value="Non-Akademik">Non Akademik</option>
                        </select>
                        <small class="text-danger" v-if="validation.kategori">{{ validation.kategori[0] }}</small>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tingkat<small>*</small></label>
                        <select class="form-control" v-model="menu.tingkat">
                          <option value="">-- Pilih Tingkat --</option>
                          <option value="Sekolah">Sekolah</option>
                          <option value="Kabupaten / Kota">Kabupaten / Kota</option>
                          <option value="Provinsi">Provinsi</option>
                          <option value="Nasional">Nasional</option>
                          <option value="Internasional">Internasional</option>
                        </select>
                        <small class="text-danger" v-if="validation.tingkat">{{ validation.tingkat[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Kegiatan <small>*</small></label>
                        <input type="text" placeholder="Nama Kegiatan" class="form-control"
                          v-model="menu.nama_kegiatan" />
                        <small class="text-danger" v-if="validation.nama_kegiatan">{{validation.nama_kegiatan[0]}}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun <small>*</small></label>
                        <input type="number" placeholder="0" class="form-control"
                          v-model="menu.tahun" @input="checkMaxLength" />
                        <small class="text-danger" v-if="validation.tahun">{{validation.tahun[0]}}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Prestasi <small>*</small></label>
                        <input type="text" class="form-control"
                          v-model="menu.prestasi" />
                        <small class="text-danger" v-if="validation.prestasi">{{validation.prestasi[0]}}</small>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tampilkan Di raport ?</label>
                      <select class="form-control" v-model="menu.tampilkan_raport">
                          <option value="Tidak">Tidak</option>
                          <option value="Ya">Ya</option>
                        </select>
                        <small class="text-danger" v-if="validation.tampilkan_raport">{{validation.tampilkan_raport[0]}}</small>
                      </div>
                    </div>
                  </div> -->


                  <div class="row">
                    <div class="col-12">
                      <input type="hidden" class="form-control"
                          v-model="menu.id" />
                      <button type="button" @click="simpan" class="btn btn-success btn-sm none">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-prestasisiswa' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from 'sweetalert2';
// import ArgonSwitch from "@/components/ArgonSwitch.vue";



export default {
  name: "PrestasiSiswaEdit",
  components: {
      // ArgonSwitch,
  },
  data() {
    return {
      showMenu: false,
      searchTerm: '', // Input value for search
      filteredSiswa: [], // Filtered results
    };
  },
  methods: {
    async changeUnit() {
      const selectedValues = this.menu.unit_id;

      if (selectedValues == ' ' || selectedValues == null || selectedValues == '') {
        this.menu.siswa = false;
      } else {
        this.menu.siswa = true;
      }

      // Reset nilai searchTerm dan filteredSiswa
      this.searchTerm = ''; // Reset searchTerm
      this.filteredSiswa = []; // Reset hasil filter siswa

      await this.$store.dispatch("siswa/getSiswaAktif", {
        page: 1,
        perPage: 10000,
        search: '',
        kelas: '',
        unit: selectedValues,
        unitlevel: '',
      });
    },
    async checkMaxLength() {
      let tahunStr = this.menu.tahun.toString();
      if (tahunStr.length > 4) {
        this.menu.tahun = tahunStr.slice(0, 4);
      }
      // console.log(" panjang ",tahunStr.length);
    },
    filterEkskul() {
      if (this.searchTerm === '') {
        this.filteredSiswa = [];
        return;
      }

      // Filter the ekskul list based on the search term
      this.filteredSiswa = this.siswas.data.filter(eks => 
        eks.fullname.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    selectEkskul(eks) {
      // Set the selected ekskul
      this.menu.student_id = `${eks.id_student}`;
      
      // Set the search term to the selected ekskul
      this.searchTerm = `${eks.fullname} (${eks.classname	})`;

      // Clear the filtered list
      this.filteredSiswa = [];

      // Call changeUnit or any method you need to trigger
      // this.changeUnit();
    }
  },
  

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const menu = reactive({
      unit_id:'',
      id_ta:'',
      student_id:'',
      kategori:'',
      tingkat:'',
      nama_kegiatan:'',
      tahun:'',
      tampilkan_raport:'',
      prestasi:'',
      siswa:false,
      id:'',
    });

    

    const data = reactive({

      pages: 'prestasisiswa-edit'
    });
    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

        //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function savePrestasi() {
      //define variable
      let unit_id = menu.unit_id;
      let id_ta = menu.id_ta;
      let student_id = menu.student_id;
      let kategori = menu.kategori;
      let tingkat = menu.tingkat;
      let nama_kegiatan = menu.nama_kegiatan;
      let tahun = menu.tahun;
      let tampilkan_raport = menu.tampilkan_raport;
      let prestasi = menu.prestasi;
      let id = menu.id;

      //panggil actions "register" dari module "auth"
      store
        .dispatch("prestasisiswa/store", {
          unit_id,
          id_ta,
          student_id,
          kategori,
          tingkat,
          nama_kegiatan,
          tahun,
          tampilkan_raport,
          prestasi,
          id,

        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-prestasisiswa" });
          Swal.fire({
            icon: 'success',
            title: 'Edit data berhasil',
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    const unituser = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["user/getuser"];
    });




    onMounted(async () => {
      await store.dispatch("units/getAll");
      await store.dispatch("permission/getAllPermission");
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch("prestasisiswa/getPeserta",route.params.id);
      await store.dispatch("siswa/getSiswaAktif",{
        page: 1,
        perPage: 10000,
        search: '',
        kelas: '',
        unit: menu.unit_id,
        unitlevel: '',
      });
      await store.dispatch('guru/getAll')
      await store.dispatch('user/getuser')
      const permissions = localStorage.getItem('permission');
      

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    watch(
      () => store.getters['prestasisiswa/getPeserta'],
      (newMenu) => {
        if (newMenu) {
          const {
            id_ta,
            nama_kegiatan,
            student_id,
            unit_id,
            kategori,
            tingkat,
            tahun,
            prestasi,
            id,
          } = newMenu;
          menu.id_ta = id_ta;
          menu.nama_kegiatan=nama_kegiatan;
          menu.student_id = student_id;
          menu.unit_id=unit_id;
          menu.kategori=kategori;
          menu.tingkat=tingkat;
          menu.tahun=tahun;
          menu.prestasi=prestasi;
          menu.id=id;
        }
      }
    );

    


    const detil = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["prestasisiswa/getPeserta"];
    });


    //computed
    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const tahunajaran = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["tahunajaran/getActive"];
    });


    const siswas = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["siswa/getSiswaAktif"];
    });



    const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAll"];
    });

    const gurus = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["guru/getAll"];
    });

    const keypermission = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["permission/getAllPermission"];
    });
    
    var klik=0;
    async function simpan() {
      // alert("ya");
      console.log(klik);
      
      if(klik<=1){
        savePrestasi();
      }else{
        Swal.fire({
          title: "Peringatan!",
          text: "Mohon tunggu....",
          icon: "error"
        });
      }

      klik++;
      
      // myForm.value.submit();
    }
    //return a state and function
    return {
      menu, // <-- state user
      validation, // <-- state validation
      savePrestasi,
      parentmenu, // <-- hook toast
      keypermission,
      tahunajaran,
      siswas,
      unitlevels,
      gurus,
      unituser,
      data,
      simpan,
      detil
    };
  },
};
</script>