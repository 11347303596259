<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0">Rekap Nilai</h5>
                <p>Kelas: {{ kelas.classname }}</p>
                <p>Tahun ajaran: {{ tasaktif.desc }}</p>
              </div>
              <div class="flex">
                <router-link
                  :to="{ name: 'admin-perwalian' }"
                  class="btn btn-primary btn-sm"
                  >Kembali</router-link
                >
                <a
                  :href="`${this.$baseURL}/export-rekap-smp-mid?kelasid=${$route.params.id}`"
                  target="_blank"
                  class="btn btn-sm btn-success"
                >
                  Export
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="isLoading">
              <ContentLoader />
            </div>

            <div class="table-responsive table-container" v-else>
              <table id="datatable-search" class="table table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th
                      rowspan="2"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle;
                        background-color: #f2f2f2;
                        z-index: 30;
                        height: 100%;
                        display: table-cell;
                      "
                    >
                      Nama
                    </th>
                    <template
                      v-for="(items, kelompok) in nilairekap.groupmapel"
                      :key="kelompok"
                    >
                      <template v-for="mapel in items" :key="mapel.id">
                        <th
                          v-if="mapel.type === 'parent'"
                          colspan="1"
                          style="
                            font-size: 10pt;
                            color: gray;
                            font-weight: bold;
                            position: sticky;
                            top: 0;
                            text-align: center;
                            background-color: #f2f2f2;
                            z-index: 20; /* Pastikan lebih rendah dari nama */
                          "
                        >
                          {{ mapel.nama_mapel }}
                        </th>

                        <th
                          v-else
                          colspan="3"
                          style="
                            font-size: 10pt;
                            color: gray;
                            font-weight: bold;
                            position: sticky;
                            top: 0;
                            text-align: center;
                            background-color: #f2f2f2;
                            z-index: 20; /* Pastikan lebih rendah dari nama */
                          "
                        >
                          {{ mapel.nama_mapel }}
                        </th>
                      </template>
                    </template>
                    <th
                      rowspan="2"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle;
                        background-color: #f2f2f2;
                        z-index: 30;
                        height: 100%;
                        display: table-cell;
                      "
                    >
                      Nilai akhir total
                    </th>

                    <th
                      colspan="3"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        top: 0;
                        background-color: #f2f2f2;
                        z-index: 20;
                      "
                    >
                      Kehadiran
                    </th>
                    <th
                      rowspan="2"
                      style="
                        font-size: 10pt;
                        color: gray;
                        font-weight: bold;
                        position: sticky;
                        left: 0;
                        top: 0;
                        text-align: center;
                        vertical-align: middle;
                        background-color: #f2f2f2;
                        z-index: 30;
                        height: 100%;
                        display: table-cell;
                      "
                    >
                      Peringkat
                    </th>
                  </tr>
                  <tr>
                    <template
                      v-for="(items, kelompok) in nilairekap.groupmapel"
                      :key="kelompok"
                    >
                      <template v-for="item in items" :key="item.id">
                        <template v-if="item.type === 'parent'">
                          <th
                            style="
                              font-size: 10pt;
                              color: gray;
                              font-weight: bold;
                              position: sticky;
                              top: 40px;
                              text-align: center;
                              background-color: #f2f2f2;
                              z-index: 10;
                            "
                          >
                            NA
                          </th>
                        </template>
                        <template v-else>
                          <template
                            v-for="type in ['P', 'K', 'NA']"
                            :key="type"
                          >
                            <th
                              style="
                                font-size: 10pt;
                                color: gray;
                                font-weight: bold;
                                position: sticky;
                                top: 40px;
                                text-align: center;
                                background-color: #f2f2f2;
                                z-index: 10;
                              "
                            >
                              {{ type }}
                            </th>
                          </template>
                        </template>
                      </template>
                    </template>

                    <template v-for="type in ['S', 'I', 'A']" :key="type">
                      <th
                        style="
                          font-size: 10pt;
                          color: gray;
                          font-weight: bold;
                          position: sticky;
                          top: 40px; /* Sesuaikan dengan tinggi header sebelumnya */
                          background-color: #f2f2f2;
                          z-index: 10;
                          text-align: center;
                        "
                      >
                        {{ type }}
                      </th>
                    </template>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="m in nilairekap.getSiswa" :key="m.id">
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;
                        position: sticky;
                        left: 0;
                        background-color: #fff;
                        z-index: 3;
                      "
                    >
                      {{ m.fullname }}
                    </td>
                    <template
                      v-for="(items, kelompok) in nilairekap.groupmapel"
                      :key="kelompok"
                    >
                      <template v-for="mapel in items" :key="mapel.id">
                        <template v-if="mapel.type === 'child'">
                          <td
                            style="
                              font-size: 10pt;
                              color: gray;

                              left: 0;
                              background-color: #fff;

                              text-align: center;
                            "
                          >
                            {{
                              getNilaiP(m.id_student, mapel.mapel_id) == null ||
                              getNilaiP(m.id_student, mapel.mapel_id) === ""
                                ? "-"
                                : Math.round(
                                    getNilaiP(m.id_student, mapel.mapel_id)
                                  )
                            }}
                          </td>
                          <td
                            style="
                              font-size: 10pt;
                              color: gray;

                              left: 0;
                              background-color: #fff;

                              text-align: center;
                            "
                          >
                            {{
                              (() => {
                                const nilai = getNilaiK(
                                  m.id_student,
                                  mapel.mapel_id
                                );
                                return nilai == null || nilai === ""
                                  ? "-"
                                  : Math.round(nilai);
                              })()
                            }}
                          </td>
                          <td
                            style="
                              font-size: 10pt;
                              color: gray;

                              left: 0;
                              background-color: #fff;

                              text-align: center;
                            "
                          >
                            {{
                              (() => {
                                const nilai = getNilaiNA(
                                  m.id_student,
                                  mapel.mapel_id
                                );
                                return nilai == null || nilai === ""
                                  ? "-"
                                  : Math.round(nilai);
                              })()
                            }}
                          </td>
                        </template>
                        <template v-else>
                          <td
                            style="
                              font-size: 10pt;
                              color: gray;

                              left: 0;
                              background-color: #fff;

                              text-align: center;
                            "
                          >
                            {{
                              Math.round(
                                getNilaiNAParent(m.id_student, mapel.mapel_id)
                              )
                            }}
                          </td>
                        </template>
                      </template>
                    </template>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getTotalNilai(m.id_student) }}
                    </td>
                    <td
                      style="
                        text-align: center;
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;
                      "
                    >
                      {{ getKehadiran(m.id_student, "S") }}
                    </td>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getKehadiran(m.id_student, "I") }}
                    </td>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getKehadiran(m.id_student, "A") }}
                    </td>
                    <td
                      style="
                        font-size: 10pt;
                        color: gray;

                        left: 0;
                        background-color: #fff;

                        text-align: center;
                      "
                    >
                      {{ getPeringkat(m.id_student) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";
// import Api from '../../../api/Api'

export default {
  name: "kelasIndex",
  components: {
    ContentLoader,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const kelas = reactive({
      classname: "",
      id_ta: "",
      wali_kelas: "",
      guru_pendamping: "",
      id_unitlevel: "",
    });
    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: "",
      raport: {
        file: "",
        id_studentactive: "",
      },
      pages: "perwalian-view",
    });

    const isLoggedIn = computed(() => {
      //get getter "isLoggedIn" dari module "auth"
      return store.getters["auth/isLoggedIn"];
    });

    const token = isLoggedIn.value;
    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const { master_kelas, id_ta, id_instructor_1, id_instructor_2 } =
            newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.wali_kelas = id_instructor_1;
          kelas.guru_pendamping = id_instructor_2;
          kelas.id_unitlevel = master_kelas.id_unitlevel;
        }
      }
    );

    const router = useRouter();
    onMounted(async () => {
      isLoading.value = true;
      await store.dispatch("siswa/getSiswaKelas", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id,
      });

      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch("nilai/getNilaiRekapSmp", route.params.id);

      await store.dispatch("tahunajaran/getActive");

      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
      setTimeout(() => {
        isLoading.value = false;
      }, 10000);
    });

    const nilairekap = computed(() => {
      return store.getters["nilai/getNilaiRekapSmp"];
    });

    const siswa = computed(() => nilairekap.value.getSiswa);
    const nilaiP = computed(() => nilairekap.value.nilaip);
    const nilaiK = computed(() => nilairekap.value.nilaik);
    const nilaiNA = computed(() => nilairekap.value.nilaiNA);
    const nilaiNAParent = computed(() => nilairekap.value.nilaiNAParent);
    const nilaiAbsen = computed(() => nilairekap.value.absen);
    const groupmapel = computed(() => nilairekap.value.groupmapel.A);

    const getNilaiP = (idSiswa, idMapel) => {
      const nilaiSiswa = nilaiP.value
        .filter(
          (item) => item.siswa_id === idSiswa && item.id_mapel === idMapel
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Urutkan berdasarkan created_at terbaru

      return nilaiSiswa.length > 0 ? nilaiSiswa[0].nilai : "";
    };

    // const getNilaiP = (idSiswa, idMapel) => {
    //   const nilai = nilaiP.value.find((item) => {
    //     return item.siswa_id === idSiswa && item.id_mapel === idMapel;
    //   });

    //   return nilai ? nilai.nilai : "";
    // };

    const getNilaiK = (idSiswa, idMapel) => {
      const nilaiSiswa = nilaiK.value
        .filter(
          (item) => item.siswa_id === idSiswa && item.id_mapel === idMapel
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Urutkan berdasarkan created_at terbaru

      return nilaiSiswa.length > 0 ? nilaiSiswa[0].nilai : "";
    };

    // const getNilaiK = (idSiswa, idMapel) => {
    //   const nilai = nilaiK.value.find((item) => {
    //     return item.siswa_id === idSiswa && item.id_mapel === idMapel;
    //   });

    //   return nilai ? nilai.nilai : "";
    // };

    const getNilaiNA = (idSiswa, idMapel) => {
      const nilaiSiswa = nilaiNA.value
        .filter(
          (item) => item.siswa_id === idSiswa && item.id_mapel === idMapel
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Urutkan berdasarkan created_at terbaru

      return nilaiSiswa.length > 0 ? nilaiSiswa[0].nilai : "";
    };

    const getNilaiNAParent = (idSiswa, idParent) => {
      // Mencari objek yang memiliki `siswa_id` dan `id_parent` yang cocok
      const na = nilaiNAParent.value.find((item) => {
        return item.siswa_id === idSiswa && item.id_parent === idParent;
      });

      // Mengembalikan nilai dari `na` jika ditemukan, atau 0 jika tidak ditemukan
      return na ? na.nilai : 0;
    };

    const getTotalNilai = (idSiswa) => {
      let totalNilai = 0;

      // Menggunakan forEach untuk iterasi `groupmapel.value` yang merupakan array
      groupmapel.value.forEach((item) => {
        if (item.type === "child") {
          // Mencari nilai NA untuk anak jika tidak memiliki parent
          if (item.id_parents === null) {
            const nilaiNa = getNilaiNA(idSiswa, item.mapel_id);
            totalNilai += nilaiNa ? Math.round(nilaiNa) : 0;
          }
        } else if (item.type === "parent") {
          // Mencari nilai NA untuk parent
          const nilaiNaParent = getNilaiNAParent(idSiswa, item.id);
          totalNilai += nilaiNaParent ? Math.round(nilaiNaParent) : 0;
        }
      });

      console.log("Total Nilai:", totalNilai); // Cetak total nilai
      return totalNilai;
    };

    const getAverageNA = (parent, siswa) => {
      // console.log(parent)
      const childs = groupmapel.value.filter(
        (child) => child.id_parents === parent.id
      );

      let totalNA = 0;
      let countNA = 0;

      childs.forEach((child) => {
        const na = nilaiNA.value.find(
          (n) =>
            n.id_mapel === child.mapel_id && n.siswa_id === siswa.id_student
        );

        if (na && na.nilai !== null && na.nilai !== "-") {
          totalNA += na.nilai;
          countNA++;
        }
      });

      return countNA > 0 ? Math.round(totalNA / countNA) : "-";
    };

    const calculateTotalNilai = (idSiswa) => {
      let totalNilai = 0;

      // Menjumlahkan semua nilai P untuk siswa tersebut
      // totalNilai += nilaiP.value
      //   .filter((item) => item.siswa_id === idSiswa)
      //   .reduce((sum, item) => sum + item.nilai, 0);

      // // Menjumlahkan semua nilai K untuk siswa tersebut
      // totalNilai += nilaiK.value
      //   .filter((item) => item.siswa_id === idSiswa)
      //   .reduce((sum, item) => sum + item.nilai, 0);

      // Menjumlahkan semua nilai NA untuk siswa tersebut
      totalNilai += nilaiNA.value
        .filter((item) => item.siswa_id === idSiswa)
        .reduce((sum, item) => sum + item.nilai, 0);

      return totalNilai;
    };

    const getPeringkat = (idSiswa) => {
      const siswaDenganTotalNilai = siswa.value.map((s) => ({
        ...s,
        totalNilai: getTotalNilai(s.id_student),
      }));

      // Mengurutkan data berdasarkan total nilai (descending)
      const sortedSiswa = [...siswaDenganTotalNilai].sort(
        (a, b) => b.totalNilai - a.totalNilai
      );

      // Menentukan peringkat berdasarkan total nilai (descending)
      let previousTotalNilai = null;
      let currentRank = 0;
      let rankCounter = 0;
      const siswaPeringkat = sortedSiswa.map((s) => {
        rankCounter++;
        if (s.totalNilai !== previousTotalNilai) {
          currentRank = rankCounter;
        }
        previousTotalNilai = s.totalNilai;
        return {
          ...s,
          peringkat: currentRank,
        };
      });

      const siswaIni = siswaPeringkat.find((s) => s.id_student === idSiswa);
      return siswaIni ? siswaIni.peringkat : "-";
    };

    // Fungsi untuk membuat daftar siswa dengan total nilai dan peringkat

    const getKehadiran = (siswaId, status) => {
      const kehadiran = nilaiAbsen.value.filter(
        (k) => k.student_id === siswaId && k.status === status
      );

      // Mengembalikan jumlah entri dengan status tertentu
      return kehadiran.length > 0 ? kehadiran.length : "-";
    };

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      getPeringkat,
      calculateTotalNilai,
      tasaktif,
      getKehadiran,
      getNilaiP,
      getNilaiK,
      getNilaiNA,
      data,
      isLoggedIn,
      token,
      kelas,
      isLoading,
      nilairekap,
      getAverageNA,
      getNilaiNAParent,
      getTotalNilai,
    };
  },
};
</script>