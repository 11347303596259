<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div v-if="loading">
        <div class="col-12">
          <div class="card">
            <ContentLoader />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
           
            <div class="card-header" >
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Narasi / Catatan Proses P5</h5>
                <router-link
                  :to="{ name: 'admin-p5' }"
                  class="btn btn-primary btn-sm"
                  >Kembali</router-link
                >
              </div>
              <div class="row">
                <dl class="row">
                  <dt class="col-sm-2">Kelas</dt>
                  <dd class="col-sm-10">{{ kelas.classname }}</dd>

                  <dt class="col-sm-2">Walikelas</dt>
                  <dd class="col-sm-10">{{ kelas.wali_kelas }}</dd>
                </dl>
              </div>
            </div>
            <div class="card-body">
              <div v-if="isLoading">
              <ContentLoader />
            </div>
              <div class="accordion" id="accordionExample" v-else>
                <div
                  class="accordion-item"
                  v-for="m in siswas.data"
                  :key="m.id"
                >
                  <p class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button d-flex justify-content-between"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#collapse${m.id}`"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <i class="fas fa-angle-double-right"></i> &nbsp;
                      {{ m.fullname }} &nbsp;
                      <div
                        v-if="m.ambilkomentar != '' || m.ambilkomentar != null"
                        hidden
                      >
                        <span v-for="j in m.ambilkomentar" :key="j.id">
                          <span v-if="j.status !== 'ok'" style="color: red">
                            ( &nbsp;{{
                              j.jenis_semester == "mid" ? "Mid" : "Akhir"
                            }}
                            Semester : {{ j.status }}&nbsp; )
                          </span>
                        </span>
                      </div>
                    </button>
                  </p>
                  <div
                    :id="`collapse${m.id}`"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-12">
                        
                          <div class="form-group" v-for="n in masterp5.data" :key="n.id">
                            <label for="">Narasi {{ n.proyek }}</label>
                            <textarea
                              class="form-control"
                              :value="getNilai(m.id, n.id)"
                              @input="
                                updateData(m.id, data.akhir_semester, $event, n.id)
                              "
                            >
                            </textarea>

                           
                          </div>

                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "kelasIndex",
  components: {
    ContentLoader,
  },

  setup() {
    const loading = ref(true);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const validation = ref([]);
    const kelas = reactive({
      classname: "",
      id_ta: "",
      wali_kelas: "",
      guru_pendamping: "",
      id_unitlevel: "",
      id_unit: "",
    });

    const data = reactive({
      // currentPage: 1,
      mid: "mid",
      akhir_semester: "akhir_semester",
      perPage: 100,
      search: "",
      raport: {
        file: "",
        id_studentactive: "",
      },
      pages: "perwalian-view",
    });

    onMounted(async () => {
      isLoading.value = true;
      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id,
      });

      await store.dispatch("masterp5/getData", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        tasId: data.ta,
        id: route.params.id
      });
      loading.value = true;
      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch(
        "groupkategorisikap/getByUnitdanTa",
        route.params.id
      );
      await store.dispatch("nilainarasip5/getDataMid", route.params.id);
      await store.dispatch("nilainarasip5/getData", route.params.id);
      await store.dispatch("keterangannilai/getAll");
      loading.value = false;
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
      setTimeout(() => {
        isLoading.value = false;
      }, 10000);
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaNilaiSikap"];
    });

    const masterp5 = computed(() => {
      return store.getters["masterp5/getData"];
    });

    const kategori = computed(() => {
      return store.getters["groupkategorisikap/getByUnitdanTa"];
    });

  
    const nilainarasip5 = computed(() => {
      return store.getters["nilainarasip5/getData"];
    });

    async function updateData(idsiswa, jenis, event, idmasterp5) {
      let formData = new FormData();
      formData.append("id_siswa", idsiswa);
      formData.append("nilai", event.target.value);
      formData.append("kelas_id", route.params.id);
      formData.append("id_master_p5", idmasterp5);

      // console.log(formData);
      await store
        .dispatch("nilainarasip5/store", formData)
        .then(() => {})
        .catch((error) => {
          validation.value = error;

          Swal.fire({
            title: "GAGAL!",
            text: validation.value.error,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    }

  
   

    const getNilai = (idSiswa, idproject) => {
      // console.log('narasi',nilainarasip5.value)
      const nilai = nilainarasip5.value.find((item) => {
        return item.id_siswa == idSiswa && item.id_master_p5 == idproject;
      });
      // console.log('hahah', nilai.nilai);
      return nilai ? nilai.nilai : "";
    };

    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const {
            wali_kelas,
            master_kelas,
            id_ta,

            guru_pendamping,
          } = newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.id_unit = master_kelas.unit_level.id_unit;
          kelas.wali_kelas = wali_kelas ? wali_kelas.fullname : "";
          kelas.guru_pendamping = guru_pendamping
            ? guru_pendamping.fullname
            : "";
          kelas.id_unitlevel = master_kelas.id_unitlevel;
        }
      }
    );

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      kategori,
      kelas,
      siswas,
      validation,
      updateData,
      masterp5,
      getNilai,
      data,
      nilainarasip5,
      loading,
      isLoading,
   
    };
  },
};
</script>