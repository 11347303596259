import Api from '../../api/Api'
const nilai = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        datap:[],
        datak:[],
        datanm:[],
        datana:[],
        datanp:[],
        datarekapsd:[],
        datarekapsmp:[],
        datarekapsma:[],
        datarekap:[],
    },

    //mutations
    mutations: {
        SET_DATA(state, datas) {
            state.datas = datas
        },
        SET_DATA_P(state, datap) {
            state.datap = datap
        },
        SET_DATA_K(state, datak) {
            state.datak = datak
        },
        SET_DATA_NM(state, datanm) {
            state.datanm = datanm
        },
        SET_DATA_NA(state, datana) {
            state.datana = datana
        },
        SET_DATA_NP(state, datanp) {
            state.datanp = datanp
        },
        SET_DATA_REKAP_SD(state, datarekapsd) {
            state.datarekapsd = datarekapsd
        },

        SET_DATA_REKAP_SMP(state, datarekapsmp) {
            state.datarekapsmp = datarekapsmp
        },

        SET_DATA_REKAP_SMA(state, datarekapsma) {
            state.datarekapsma = datarekapsma
        },

        SET_DATA_REKAP(state, datarekap) {
            state.datarekap = datarekap
        },

    },

    _actions: {
        store({ commit }, formData) {
         

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/nilai', formData)

                    .then(response => {

                        
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getData({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
          
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/getbykelas?id=${payload}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA', response.data.data)

                })
        },

        getDataP({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
            let idmapel = payload ? payload.idmapel : '';
            let idunit = payload ? payload.idunit : '';
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/get-p-bykelas?id=${idmapel}&idunit=${idunit}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_P', response.data.data)

                })
        },



        getDataK({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
            let idmapel = payload ? payload.idmapel : '';
            let idunit = payload ? payload.idunit : '';
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/get-k-bykelas?id=${idmapel}&idunit=${idunit}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_K', response.data.data)

                })
        },

        getDataNM({ commit }, payload) {
            //define variable token
            const token = localStorage.getItem('token')
            let idmapel = payload ? payload.idmapel : '';
            let idunit = payload ? payload.idunit : '';
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/get-nm-bykelas?id=${idmapel}&idunit=${idunit}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_NM', response.data.data)

                })
        },

        getDataNA({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
            let idmapel = payload ? payload.idmapel : '';
            let idunit = payload ? payload.idunit : '';
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/get-na-bykelas?id=${idmapel}&idunit=${idunit}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_NA', response.data.data)

                })
        },

        getDataNP({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
            let idmapel = payload ? payload.idmapel : '';
            let idunit = payload ? payload.idunit : '';
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/get-np-bykelas?id=${idmapel}&idunit=${idunit}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_NP', response.data.data)

                })
        },

        getDataRekap({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
            let idkelas = payload ? payload.idkelas : '';
            let jenis_semester = payload ? payload.jenis_semester : '';
            let ta = payload ? payload.idta : '';
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/rekap-nilai?jenis_semester=${jenis_semester}&kelasid=${idkelas}&idta=${ta}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_REKAP', response.data.data)

                })
        },

        getNilaiRekapSd({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
         
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/rekap-sd-mid?kelasid=${payload}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_REKAP_SD', response.data.data)

                })
        },

        getNilaiRekapSmp({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
         
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/rekap-smp-mid?kelasid=${payload}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_REKAP_SMP', response.data.data)

                })
        },

        getNilaiRekapSma({ commit }, payload) {

          
            //define variable token
            const token = localStorage.getItem('token')
         
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilai/rekap-sma-mid?kelasid=${payload}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA_REKAP_SMA', response.data.data)

                })
        },

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getNilaiRekapSd(state) {
            return state.datarekapsd
        },
        getNilaiRekapSmp(state) {
            return state.datarekapsmp
        },
        getNilaiRekapSma(state) {
            return state.datarekapsma
        },
        getDataRekap(state){
            return state.datarekap
        },
        getData(state) {
            return state.datas
        },
        getDataP(state) {
            return state.datap
        },
        getDataK(state) {
            return state.datak
        },
        getDataNM(state) {
            return state.datanm
        },
        getDataNA(state) {
            return state.datana
        },
        getDataNP(state) {
            return state.datanp
        },
    }

}

export default nilai